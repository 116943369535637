<template>
  <div class="d-flex flex-column" style="height:100%">
    <div class="flex-grow-1 pa-2 pb-8 px-md-4 px-3 overflow-y-auto scrollable-content">
      <div v-if="!hideActions" class="d-flex py-1">
        <div
          class="d-flex align-center secondary-button ml-n1"
          tabindex="0"
          @click="$emit('input', null)"
        >
          <v-icon>mdi-chevron-left</v-icon>
          Назад
        </div>
        <v-spacer />
<!--        <v-icon v-if="!item.likeCount" @click="like" class="secondary-button mx-2" tabindex="0">-->
<!--          mdi-heart-outline-->
<!--        </v-icon>-->
        <v-btn small @click="like" shaped class="pr-2 mr-n1 ma-0 secondary-button" text>
          <v-icon size="24"   :color="item.likedByMe && 'red darken-1'" left>{{item.likedByMe ? 'mdi-heart' : 'mdi-heart-outline'}}</v-icon>
          <span :class="item.likedByMe && 'red--text text--darken-1'">{{ item.likeCount }}</span>
        </v-btn>
        <v-icon @click="activateSaveTo" :color="itemSaved && 'amber darken-1'" class="secondary-button mx-2" tabindex="0">
          {{itemSaved ? 'mdi-bookmark' : 'mdi-bookmark-outline'}}
        </v-icon>
        <v-menu offset-y nudge-bottom="8" left>
          <template #activator="{on}">
            <v-icon v-on="on" class="secondary-button" tabindex="0"
            >mdi-dots-horizontal
            </v-icon>
          </template>
          <v-sheet style="width: 160px;">
            <v-list>
              <v-list-item>Подписаться</v-list-item>
            </v-list>
          </v-sheet>
        </v-menu>
      </div>
      <div style="height: 0px; margin: 2px 0px; position: relative">
        <v-fade-transition>
          <v-progress-linear rounded absolute indeterminate v-if="loading" />
        </v-fade-transition>
      </div>
      <template v-if="!loading">
        <router-link class="title-link mt-2" :to="'/objects/' + itemId"><div v-text="item.title"></div></router-link>
        <div v-if="item.location" class="text-body-2 d-flex align-start my-1" ><v-icon class="mr-1" size="20">mdi-map-marker-outline</v-icon><span> {{item.location}}</span></div>
        <div v-if="item.parentObject">Входит в состав <a @click="$emit('input', item.parentObject)">{{item.parentObject.title}}</a></div>

        <OCMediaPreview
          :key="itemId"
          allow-add
          :add-to="itemId"
          carousel
          :media="item.media"
          :height="$vuetify.breakpoint.smAndDown ? 180 : 208"
        />
        <ExpandableParagraph v-if="item.note" :text="item.note" />
        <template v-for="entry in properties">
          <div
            v-text="entry['name'] + ':'"
            class="body-2 mt-2 text--secondary"
            :key="entry['name']"
          ></div>
          <div class="d-flex align-center" :key="entry['value']">
            <div style="white-space: pre-line" v-text="entry['value']"></div>
            <span v-if="entry['trait'] === 'species'" :style="{background: getTreeBackground(entry.value)}" class="tree-indicator ml-1"></span>
<!--            <v-menu offset-y :close-on-content-click="false">-->
<!--              <template #activator="{on}">-->
<!--                <v-icon-->
<!--                  size="24"-->
<!--                  v-on="on"-->
<!--                  v-if="entry['trait'] === 'species'"-->
<!--                  class="secondary-button mx-1"-->
<!--                >mdi-help-circle-outline-->
<!--                </v-icon>-->
<!--              </template>-->
<!--              <v-sheet class="d-flex pr-4 rounded" min-height="200">-->
<!--                <v-img-->
<!--                  class="my-2"-->
<!--                  width="120"-->
<!--                  height="120"-->
<!--                  src="@/assets/oak.png"-->
<!--                ></v-img>-->
<!--                <div class="pa-4">-->
<!--                  <div class="font-weight-bold">Дуб болотный</div>-->
<!--                  <div class="">Quercus palustris</div>-->
<!--                </div>-->
<!--              </v-sheet>-->
<!--            </v-menu>-->
          </div>
        </template>
      </template>
      <a class="my-3 d-block" @click="$emit('filter', item)" block text v-if="isContainer">
        Показать входящие объекты
      </a>
    </div>
    <div class="d-flex flex-column align-center">
      <v-divider class="align-self-stretch" />
      <div class="d-flex align-center">
        <v-btn
          class="text-none ma-2"
          depressed
          :to="`/objects/${itemId}`"
        >На страницу объекта
        </v-btn>
      </div>
    </div>
    <SaveToDialog @save="onListsUpdate" :items="items" v-model="showSaveTo" />
  </div>
</template>
<script>
import opencity, { toText } from "@/service/opencity";
import ExpandableParagraph from "@/components/ExpandableParagraph.vue";
import OCMediaPreview from "@/components/object/OCMediaPreview";
import SaveToDialog from "@/components/object/SaveToDialog.vue";
import useAuth from "@/mixins/useAuth";


const treeColors = {
  Дуб: "#386703",
  Берёза: "#B8E760",
  Тополь: "#7DA422",
  Липа: "#099922",
  Яблоня: "#B0C901",
  Клён: "#DD7C51",
  Груша: "#E38B1D",
  Слива: "#7ED6BB",
  Рябина: "#FA5C24",
  Ясень: "#60EBDA",
  Ирга: "#7BBCC2",
  Сирень: "#68A5E0",
  Лиственница: "#33BE91",
  Вяз: "#4D9D3B",
  Ива: "#76BF54",
  Сосна: "#91AA75",
  Можжевельник: "#4D9D3B",
  Ель: "#035505",
  Черемуха: "#2F8C9B",
  Туя: "#928720",
  Пихта: "#386703",
  Ольха: "#7DA422",
  Лох: "#7F7D6C",
  Облепиха: "#E0B745",
  Орех: "#DDAC60",
  Абрикос: "#F47919",
  Вишня: "#BD5015",
  Лавр: "#B0C901",
  Боярышник: "#A25425",
  Кедр: "#AA7241"
};

export default {
  name: "OCObjectDataCard",
  components: {SaveToDialog, OCMediaPreview, ExpandableParagraph },
  props: {
    value: {
      type: [Number, Object],
      default: null
    },
    hideActions: {
      type: Boolean
    }
  },
  data: () => ({
    fetchedItem: null,
    loading: false,
    currentSlide: 0,
    showSaveTo: false,
    itemSaved: false
  }),
  mixins: [
    useAuth
  ],
  computed: {
    items() {
      return [this.item];
    },
    item() {
      const { value } = this;
      if (typeof value === "number") return this.fetchedItem;
      return value;
    },
    itemId() {
      const { value } = this;
      if (typeof value === "number") return this.value;
      return this.item.id;
    },
    properties() {
      if (!this.item) return null;
      const props = this.item.data;
      const res = [];
      const type = opencity.getObjectType(this.item.objectType.id);
      const title = opencity.getParentCategory(type.id).title + " / " + type.title;
      res.push({
        name: "Категория / Тип объекта",
        value: title
      });
      for (let field of type.schema.fields) {
        let raw_value = props[field.key];
        if (!raw_value) continue;
        let name = field.name;
        let value = toText(field.type, raw_value);
        let match = name.match(/ \((\S+)\)$/);
        let measure = match?.[1];
        if (measure) {
          name = name.replace(match?.[0], "");
          value = value + " " + measure;
        }
        let trait;
        if (field.type === "genera") {
          trait = "species";
        }
        res.push({
          name,
          value,
          trait
        });
      }
      return res;
    },
    isContainer() {
      return this.item.objectType.id >= 2 && this.item.objectType.id <= 4;
    }
  },
  methods: {
    like() {
      if (!opencity.authenticated) {
        this.$router.push(`/login?redirect=${ encodeURI(this.$route.fullPath) }`)
      }
      if (this.item.likedByMe) {
        opencity.delete(`/objects/${this.itemId}/likes`).then(msg => {
          if (msg.ok) {
            this.item.likedByMe = false;
            this.item.likeCount -= 1;
          }
        });
      } else {
        opencity.post(`/objects/${this.itemId}/likes`).then(msg => {
          if (msg.ok) {
            this.item.likedByMe = true;
            this.item.likeCount += 1;
          }
        });
      }
    },
    activateSaveTo() {
      if (this.OCAuthenticated) {
        this.showSaveTo = true
      } else {
        this.$router.push(`/login?redirect=${ encodeURI(this.$route.fullPath) }`)
      }
    },
    getTreeBackground(genus) {
      return treeColors[genus];
    },
    onListsUpdate(data) {
      this.itemSaved = data.some(el => el.allIncluded);
    }
  },
  created() {
    this.$watch(
      "value",
      val => {
        if (typeof val === "number") {
          this.loading = true;
          opencity.getObject(val).then(data => {
            this.fetchedItem = data;
            this.loading = false;
          });
          opencity.get("/lists?objectId=" + val).then(res => res.json()).then(data => {
            this.onListsUpdate(data);
          })
        }
      },
      { immediate: true }
    );
  }
};
</script>
<style lang="scss">
.tree-indicator {
  content: "";
  width: 16px;
  height: 16px;
  border-radius: 8px;
}

.v-application a.title-link, a.title-link {
  color: currentColor;
  font-size: 1.25rem;
  line-height: normal;
  font-weight: 700;
  margin: 8px 0;
  display: block;
  &:hover {
    color: var(--v-anchor-base);
  }
}

.secondary-button {
  opacity: 0.7;
  cursor: pointer;
  user-select: none;

  &.v-icon {
    color: inherit !important;
  }

  &:hover,
  &:focus {
    opacity: 1;
  }
}

.data-value {
  line-height: normal;
  white-space: pre-line;
  font-size: 1rem;
  margin-bottom: 1rem;
  .theme--dark & {
  }
}
</style>
