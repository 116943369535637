<template>
  <i :style="{width: size, height: size}" class="d-inline-flex">
    <slot/>
  </i>
</template>
<script>
export default {
  name: 'SvgIcon',
  props: {
    size: {
      default: '24px'
    }
  }
}
</script>
<style lang="scss">

html {
  overflow-y: auto;
}

.appbar__service-block a {
  display: block;
  color: #313D43 !important;
  margin: 16px 0;
}
</style>
