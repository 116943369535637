<template>
  <v-sheet rounded class="overflow-hidden">
    <v-list dense>
      <v-list-item :to="'/users/' + userId" exact>Мой профиль</v-list-item>
      <v-list-item :to="'/users/' + userId + '/data'" exact>Мои действия</v-list-item>
      <v-list-item exact>Мои списки</v-list-item>
      <v-list-item to="/settings" exact>Настройки</v-list-item>
    </v-list>
  </v-sheet>
</template>
<script>
export default {
  name: 'UserNavBlock',
  props: {
    userId: {}
  }
}
</script>
