<template>
  <v-combobox
    :value="evalValue"
    @input="onInput"
    :items="regions"
    return-object
    :placeholder="defaultValue.name"
    item-text="name"
    v-bind="$attrs"
    :clearable="!isDefault && !isTopLevel"
    ref="searchZone"
    type="search"
  >
    <template #prepend-item>
      <v-dialog
        scrollable
        max-width="420"
        v-model="showObjectDialog"
        :fullscreen="!$vuetify.breakpoint.mdAndUp"
      >
        <v-card height="420">
          <div class="d-flex pa-2 pl-4 flex-grow-0" dense elevation="0">
            <v-toolbar-title class="font-weight-medium"
              >Выбор объекта</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-btn icon @click="showObjectDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <v-card-text class="px-4 overflow-hidden d-flex flex-column">
            <v-text-field
              :loading="loading"
              v-model="objectSearch"
              hide-details
              class="mb-2"
              prepend-inner-icon="mdi-magnify"
              outlined
              dense
              placeholder="Поиск"
            ></v-text-field>
            <v-progress-linear indeterminate :active="loading" />
            <v-list
              class="flex-grow-1 rounded v-sheet--outlined"
              dense
              style="height: 100%; overflow-y: auto"
            >
              <v-list-item
                v-show="!loading"
                @click="selectObject(i)"
                v-for="i in objects"
                :key="i.id"
                >{{ i.title }}</v-list-item
              >
              <div class="text-center" v-if="!loading && !objects.length">
                Ничего не найдено
              </div>
            </v-list>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-list-item
        dense
        @click="openObjectDialog"
        v-if="!$refs.searchZone || !$refs.searchZone.isSearching"
      >
        <v-list-item-avatar size="24">
          <v-icon size="18">mdi-map-search-outline</v-icon>
        </v-list-item-avatar>
        <v-list-item-title>
          Выбрать объект
        </v-list-item-title>
      </v-list-item>
      <v-divider
        class="my-1"
        v-if="$refs.searchZone && !$refs.searchZone.isSearching"
      />
    </template>
  </v-combobox>
</template>
<script>
import opencity from "@/service/opencity";

export default {
  name: "ZoneSelector",
  props: {
    value: {}
  },
  data: () => ({
    regions: null,
    showObjectDialog: false,
    objects: [],
    loading: false,
    objectSearch: ""
  }),
  created() {
    opencity
      .getRegions()
      .then(
        reg => (reg.forEach(el => (el.type = "region")), (this.regions = reg))
      );
    this.$watch(
      () => this.objectSearch,
      () => {
        this.fetchObjects();
      }
    );
  },
  computed: {
    defaultValue() {
      const reg = opencity.getDefaultRegion();
      reg.type = "region";
      return reg;
    },
    evalValue() {
      let val = this.value || this.defaultValue;
      if (!val.name) {
        val = { ...val, name: `[${val.type} ${val.id}]` };
        if (this.regions && val.type === "region") {
          val = this.regions.find(el => el.id == val.id);
        } else if (val.type === "object") {
          const item = this.objects.find(el => el.id == val.id);
          if (item) {
            item.name = item.title;
            item.type = "object";
            val = item;
          } else {
            // eslint-disable-next-line vue/no-async-in-computed-properties
            opencity.getObject(val.id).then(el => {
              this.objects.push(el);
            });
          }
        }
      }
      return val;
    },
    isDefault() {
      const { evalValue } = this;
      return (
        evalValue.type === this.defaultValue &&
        evalValue.id === this.defaultValue.id
      );
    },
    isTopLevel() {
      return this.evalValue.type === "region" && !this.evalValue.parentRegion;
    }
  },
  methods: {
    onInput(val) {
      if (val?.type) {
        if (val.type === "region" && !val.parentRegion) val = null;
        this.$emit("input", val);
      } else if (!val) {
        this.$emit("input", null);
      }
    },
    selectObject(o) {
      o.type = "object";
      o.name = o.title;
      this.showObjectDialog = false;
      this.$emit("input", o);
    },
    openObjectDialog() {
      this.showObjectDialog = true;
      this.objectSearch = "";
      this.fetchObjects();
    },
    async fetchObjects() {
      const types = [2, 3, 4, 31, 32, 33, 34, 35, 36, 17, 19];
      const promise = (this.loading = opencity.listObjects(
        100,
        0,
        "title,asc",
        this.objectSearch,
        types
      ));
      const result = (await promise)?.content;
      if (this.loading === promise) {
        this.loading = false;
        this.objects = result ?? [];
      }
    }
  }
};
</script>

<style lang="scss"></style>
