import Mapbox from "mapbox-gl";

const MapUtils = {
    featureFocusBounds(featureGeometry) {
        const geoType = featureGeometry.type;

        if (geoType == "MultiPolygon") {
            const coordinates = featureGeometry.coordinates;

            const bounds = new Mapbox.LngLatBounds();

            for (const polygon of coordinates) {
                for (const coord of polygon[0]) {
                    bounds.extend(coord);
                }
            }

            return bounds;
        } else if (geoType == "Point") {
            const point = new Mapbox.LngLat(...featureGeometry.coordinates);
            return point.toBounds(60);
        } else if (geoType.toUpperCase() == "MULTILINESTRING") {
            const coordinates = featureGeometry.coordinates;
            const bounds = new Mapbox.LngLatBounds();
            for (const linestring of coordinates) {
                for (const coord of linestring) {
                    bounds.extend(coord)
                }
            }

            return bounds;
        }
    }
}

export default MapUtils;