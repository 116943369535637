<template>
  <div>
    <v-dialog v-model="savetoDialog" scrollable max-width="300px">
      <SaveToListModal
        v-if="savetoDialog"
        type="object"
        :items="items"
        @change="saveToList"
        @save="$emit('save', $event)"
        @close="savetoDialog = false"
      />
    </v-dialog>
    <v-snackbar right dark :text="snackItem" :timeout="3000" v-model="snack">
      {{ snackItem.allIncluded ? "Сохранено в " : "Удалено из " }}
      <b>{{ snackItem.name }}</b>
      <v-btn color="accent" class="float-end" text @click="snack = false">
        Закрыть
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import SaveToListModal from "@/components/SaveToListModal";

export default {
  name: "SaveToDialog",
  components: { SaveToListModal },
  props: {
    value: {},
    items: {},
  },
  data: () => ({
    snackItem: "",
    snack: false
  }),
  computed: {
    savetoDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  methods: {
    saveToList(item) {
      this.snackItem = item;
      this.snack = true;
      this.emit(save);
    },
  }
};
</script>
<style lang="scss" scoped>
.table-block::v-deep th:first-child {
  padding-right: 0;
}
</style>
