import { render, staticRenderFns } from "./AddIssueMapStep.vue?vue&type=template&id=077fa638&"
import script from "./AddIssueMapStep.vue?vue&type=script&lang=js&"
export * from "./AddIssueMapStep.vue?vue&type=script&lang=js&"
import style0 from "./AddIssueMapStep.vue?vue&type=style&index=0&id=077fa638&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports