import opencity from "@/service/opencity";
import {defaultFilterDefinitions} from "@/assets/filters";

export function filterOptionsToQueryObject(o) {
  o = o ?? {};
  const query = {};
  if (o.query) query.q = o.query;
  if (o.category) query.cat = o.category;
  if (o.category?.id) query.cat = o.category.id;
  if (o.type) query.type = o.type;
  if (o.type?.id) query.type = o.type.id;
  if (o.advanced) query.advanced = true;
  if (o.types) query.types = o.types.join(',');
  if (o.zone && o.zone.id) query.zone = o.zone.type + "," + o.zone.id;
  for (const [k, v] of Object.entries(o)) {
    if (!k.startsWith("tag~")) continue;
    if (!defaultFilterDefinitions[k]) continue;
    if(defaultFilterDefinitions[k].isEmpty(v)) continue;
    const tag = k.substring(4);
    // query[`data-${tag}`] = defaultFilterDefinitions[k].serialize(v);
  }
  // if (o.created?.from) query.createdFrom = o.created.from;
  // if (o.created?.to) query.createdTo = o.created.to;
  // if (o.updated?.from) query.updatedFrom = o.updated.from;
  // if (o.updated?.to) query.updatedTo = o.updated.to;
  if (o.visibility !== undefined && o.visibility !== null)
    // query.visible = o.visibility;
  if (o.list) query.list = o.list;

    const q = {};
    if (o.page && o.page !== 1) q.page = o.page;
    if (o.author) q.author = o.author;
    if (o.itemsPerPage && o.itemsPerPage !== 20)
        q.pageSize = o.itemsPerPage;
    const sortString =
        o.sortBy?.[0] + "," + (o.sortDesc?.[0] ? "desc" : "asc");
    if (o.sortBy?.[0] && sortString !== "updatedAt,desc")
        q.sortBy = sortString;

  Object.entries(defaultFilterDefinitions).forEach(([key, def]) => {
    if (def.isEmpty(o[key])) return;
    q[key] = def.serialize(o[key]);
  });


  return { ...q, ...query };
}

export function queryObjectToFilterOptions(query) {
  let options = {};
  options.query = query.q;
  options.category = query.cat && JSON.parse(
      JSON.stringify(
          this.OCClassifier.find(el => el.id === Number(query.cat))
      ));
  options.type = options.category?.objectTypes.find(
      el => el.id === Number(query.type)
  );
  // const def = options.emptyDataFilters();
  options.dataFilters = Object.entries(query).reduce((o, e) => {
    if (!e[0].startsWith("data-")) return o;
    let key = e[0].substring(5);
    let val = e[1];
    if (key.includes("~")) {
      key = key.split("~")[0];
      val = {
        ...o[key],
        [e[0].split("~")[1]]: e[1]
      };
    }
    o[key] = val;
    return o;
  }, {});
  let region, parent;
  if (query.zone) {
    const tokens = query.zone.split(",");
    if (tokens[0] === "region") region = tokens[1];
    else if (tokens[0] === "object") parent = tokens[1];
  }
  options.types = query.types?.split(",").map(el => Number(el));
  options.created = {
    from: query.createdFrom,
    to: query.createdTo
  };
  options.updated = {
    from: query.updatedFrom,
    to: query.updatedTo
  };
  options.myObjects = query.myObjects;
  options.list = query.list && Number(query.list);
  if (query.visible === "true") options.visibility = true;
  else if (query.visible === "false") options.visibility = false;

  return options;
}

function applyFilter(filter, queryObject) {
  const { type, value } = filter;
  switch (type) {
    case "searchString": queryObject.q = value; return;
    case "types": queryObject.types = value.join(","); return;
    default: queryObject[type] = value; return;
  }
}

class QueryBuilder {
  filters = [];
  sort = null;
  pagination = null;

  addFilterToQueryObject(filter, queryObject) {
    if (filter.type === 'searchString') {
      queryObject.q = filter.value;
    }
  }

  toAPIQueryObject() {
    let result = {};
    result.page = this.pagination?.page ?? 0;
    result.size = this.pagination?.size ?? 20;
    result.sort = this.sort ? `${this.sort.type},${this.sort.order},ignorecase` : 'updatedAt,desc,ignorecase';
    result.q = this.filters?.query;
    result.types = this.filters?.types.join(",");
    result.regions = this.filters?.regions.join(",");
    result.parent = this.filters?.parentObjectId;
    const dataFilters = this.filters?.dataFilters;
    if (dataFilters)
      Object.entries(dataFilters).forEach(([k, v]) => {
        if (typeof v === "object") {
          if (v?.from) result[`data[${k}]`] = `gt~${v.from}`;
          if (v?.to) result[`data[${k}]`] = `lt~${v.to}`;
          return;
        }
        if (v) {
          result[`data${k}`] = v;
        }
      });
    result.createdFrom = this.filters?.createdRange?.from;
    result.createdTo = this.filters?.createdRange?.to;
    result.updateFrom = this.filters?.updatedRange?.from;
    result.updatedTo = this.filters?.updatedRange?.to;
    result.author = this.filters?.author;
    result.visible = this.filters?.visibility;
    result.list = this.filters?.list;
    result.moderated = this.filters?.moderated;

    return result;
  }
}
