<template>
  <v-banner color="warning" v-if="!hideBanner">
    <div class="c-container">
      <v-icon @click="hideBanner = true" class="mx-md-2 ml-2 float-end">mdi-close</v-icon>
      Портал работает в тестовом режиме. С вопросами и предложениями обращайтесь: 8 (342) 210-99-91, email:
      uep@gorodperm.ru
    </div>2
  </v-banner>
</template>
<script>
export default {
  name: 'AppInfoBanner',
  data() {
    return {
      hideBanner: true
    }
  }
}
</script>
<style lang="scss">

html {
  overflow-y: auto;
}

.c-container {
  width: 100%;
  max-width: 1080px;
  padding: 0 16px;
  margin: auto;
}

.appbar__service-block a {
  display: block;
  color: #313D43 !important;
  margin: 16px 0;
}
</style>
