<template>
  <div class="d-flex align-center flex-column w-100">
    <span id="stage-4" class="text-h5 font-weight-bold my-6 my-md-8" auto-grow
    >3. Описание</span
    >
    <v-form
        v-model="valid"
        class="d-flex align-center flex-column w-100"
        ref="form"
    >
      <v-autocomplete
          :rules="genusRules"
          v-model="value.data.genus"
          label="Род*"
          @change="blur"
          :items="genera"
          item-text="name"
          return-object
          outlined
          dense
          class="w-100"
      >
        <template v-slot:item="{ item }">
          <v-list-item-content>
            <v-list-item-title v-text="item.name"></v-list-item-title>
            <v-list-item-subtitle v-text="item.lat"></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>
      <v-autocomplete
          v-model="value.data.species"
          item-text="name"
          item-value="value"
          @change="blur"
          outlined
          dense
          class="w-100"
          label="Вид"
          :items="species"
      ></v-autocomplete>
      <v-text-field
          :rules="trunkDiamRules"
          v-model.number="value.data.trunk_diam"
          label="Диаметр ствола (см)*"
          min="0"
          outlined
          dense
          type="number"
          class="w-100"
      >
      </v-text-field>
      <v-text-field
          v-model.number="value.data.crown_diam"
          label="Диаметр кроны (м)"
          min="0"
          outlined
          dense
          type="number"
          class="w-100"
      >
      </v-text-field>
      <v-text-field
          v-model.number="value.data.height"
          label="Высота (м)"
          min="0"
          outlined
          dense
          type="number"
          class="w-100"
      >
      </v-text-field>
      <v-text-field
          v-model.number="value.data.trunk_count"
          label="Количество стволов"
          min="0"
          outlined
          dense
          type="number"
          class="w-100"
      >
      </v-text-field>
      <v-text-field
          v-model.number="value.data.plant_year"
          label="Год посадки"
          min="0"
          outlined
          dense
          type="number"
          class="w-100"
      >
      </v-text-field>
      <v-select
          v-model="value.data.age"
          clearable
          :items="['1 - Молодое', '2 - Средневозрастное', '3 - Приспевающее', '4 - Спелое', '5 - Перестойное']"
          label="Класс возраста"
          outlined
          dense
          class="w-100"
      >
      </v-select>
      <v-select
          v-model="value.data.condition"
          label="Состояние дерева"
          :items="conditions"
          return-object
          min="0"
          outlined
          dense
          type="number"
          class="w-100"
      >
      </v-select>
      <v-autocomplete
          multiple
          v-model="value.data.damage"
          label="Повреждения"
          :items="damage"
          item-text="name"
          return-object
          outlined
          dense
          class="w-100"
      >
      </v-autocomplete>
      <v-textarea
          v-model="value.note"
          label="Примечание"
          outlined
          dense
          counter
          maxlength="2000"
          class="w-100"
      >
      </v-textarea>

<!--      <v-select-->
<!--          v-model="value.visible"-->
<!--          label="Видимость"-->
<!--          mandatory-->
<!--          :items="[-->
<!--          { value: true, label: 'Для всех' },-->
<!--          { value: false, label: 'Для команды' }-->
<!--        ]"-->
<!--          item-text="label"-->
<!--          item-value="value"-->
<!--          outlined-->
<!--          dense-->
<!--          class="w-100 mt-4"-->
<!--          hide-details-->
<!--      >-->
<!--      </v-select>-->
    </v-form>
    <!-- <div class="w-100">
        <TextButton class="font-weight-medium text-body-2" color="primary"><v-icon size="24" left>mdi-paperclip</v-icon>Додати файл</TextButton>
        <div class="pl-11 text-body-2 text--disabled">не більше 3 файлів в форматі pdf, doc, docx розміром не більше 10 Мб</div>
    </div> -->
    <div class="w-100 mt-4 primary--text">
      <!-- <v-checkbox v-model="priv" class="ml-2" hide-details color="primary">
        <template v-slot:label>
            <span class="font-weight-medium text-body-2 primary--text private-checkbox-label">Сделать приватным</span>
        </template>
        </v-checkbox>
        <div class="pl-11 text-body-2 text--disabled">інші не побачать це повідомлення</div> -->
      <v-expand-transition key="unique">
        <div v-if="priv">
          <div
              class="
        warning-block
        font-weight-medium
        px-4
        py-3
        px-md-8
        py-md-6
        d-flex
        align-center
        mt-4
        "
          >
            <v-icon color="warning" class="mr-4 flex-shrink-0" size="32"
            >mdi-alert</v-icon
            >

          </div>
        </div>
      </v-expand-transition>
    </div>
  </div>
</template>

<script>
import TextButton from "./TextButton.vue";

import genera, { species } from "../../../assets/genera.js";

const conditions = ["Хорошее", "Удовлетворительное", "Неудовлетворительное"];

import damage from "./damage";

export default {
  data: () => ({
    priv: false,
    genera,
    conditions,
    genusRules: [v => v != null || "Обязательное поле"],
    trunkDiamRules: [
      v => v != null || "Обязательное поле",
      v => v >= 0 || "Значение должно быть положительным"
    ],
    crownDiamRules: [
      v => v != null || "Обязательное поле",
      v => v >= 0 || "Значение должно быть положительным"
    ],
    ageRules: [v => v != null || "Обязательное поле"],
    valid: false
  }),
  methods: {
    setStage(st) {
      this.$emit("update:stage", st);
    },
    validate() {
      this.$refs.form.validate();
    },
    blur() {
      window.document.activeElement.blur();
    }
  },
  computed: {
    damage() {
      // console.log(damage)
      return damage.reduce((o, el) => {
        // console.log(el)
        el.items.forEach(l => o.push({ name: l, group: el.group }));
        return o;
      }, []);
    },
    allowedToProceed() {
      return this.$refs.form.value;
    },
    species() {
      const { genus } = this.value.data;
      if (!genus) return species;
      return species.filter(el => el.parent === genus);
    }
  },
  watch: {
    valid(val) {
      this.$emit("unlock", val);
    },
    "value.data.species"(val) {
      if (!val) return;
      this.value.data.genus = species.find(el => el.name === val)?.parent;
    }
  },
  props: {
    stage: {
      type: Number
    },
    value: {
      type: Object
    }
  },
  components: {
    // TextButton
  }
};
</script>

<style></style>
