import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import gmaps from './plugins/gmaps'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'

import 'mapbox-gl/dist/mapbox-gl.css'

import VueYandexMetrika from 'vue-yandex-metrika'
Vue.config.productionTip = false


Vue.use(Vue => {
  Vue.mixin({
    beforeCreate() {
      this.$debugger = function() {
        debugger;
      };
      this.$blur = function() {
        document.activeElement.blur();
      };
      this.$log = function(val) {
        console.log("Using $log: ", val);
      };
    }
  });
});

if (process.env.VUE_APP_YANDEX_METRIKA_ID != null) {
  Vue.use(VueYandexMetrika, {
    router,
    id: process.env.VUE_APP_YANDEX_METRIKA_ID,
    env: process.env.NODE_ENV
  })
}

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
