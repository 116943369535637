<template>
  <div class="c-container page">
    <div v-if="article" class="d-flex my-4 align-start">
      <article style="flex: 1 1 50%">
        <span v-if="article" class="page-title font-weight-medium" v-text="article.title"></span>
        <span class="d-block mb-4 text--secondary" v-text="article.published_date"></span>
        <img class="cover rounded-lg" :src="article.cover" v-if="article && article.cover"/>
        <div v-html="article.content"></div>
      </article>
      <div class="info--sidebar pl-6 d-none d-md-block">
<!--        <v-btn block color="blue" dark depressed class="mb-3 py-5" disabled>Подписаться на новости</v-btn>-->
        <v-sheet rounded class="overflow-hidden">
          <v-list dense>
            <v-list-item to="/news" exact>Все новости</v-list-item>
<!--            <v-list-item exact>ГАЗЕТА “ЗДРАВСТВУЙ!”</v-list-item>-->
            <v-list-item exact>Избранное</v-list-item>
          </v-list>
        </v-sheet>
        <ShareBlock />
      </div>
    </div>
  </div>
</template>

<script>
import {fetchNews} from "@/data";
import ShareBlock from "@/components/ShareBlock.vue";

export default {
  name: "Info",
  components: {ShareBlock},

  metaInfo() {
    return {
      title: this.article?.title
    }
  },

  data() {
    return {
      news: []
    }
  },
  async created() {
    this.news = await fetchNews()
  },
  computed: {
    article() {
      return this.news.find(el => el.id == this.$route.params.id);
    }
  }
}
</script>

<style lang="scss" scoped>
.cover {
  max-width: 100%;
  max-height: 480px;
}

.info--sidebar {
  width: 340px;
  flex: 0 1 auto;
  .v-sheet {
    box-shadow: 0 0 8px rgb(0 0 0 / 8%);
    .v-list-item {
      text-transform: uppercase;
      font-weight: 500;
      opacity: 0.7;
      font-size: 14px;
    }
    a.v-list-item--active {
      opacity: 1;
    }
    a:hover {
      text-decoration: none;
    }
  }
  position: sticky;
  top: 12px;
}

article {
  word-break: break-word;


  ::v-deep h2 {
    font-weight: 500;
    margin: 8px 0;
  }
}
</style>
