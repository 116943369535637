<template>
  <div>
    <div class="c-container page" v-if="!success">
      <div class="d-flex">
        <span class="text-h5 font-weight-bold">Вход</span>
        <v-spacer/>
      </div>
      <v-form ref="form" lazy-validation @submit.prevent="submit" class="py-4" style="max-width: 580px">
        <div>Email</div>
        <v-text-field id="email" v-model="email" :rules="[required_rule, email_rule]" autocomplete="email" persistent-hint outlined dense filled></v-text-field>
            <div>Пароль</div>
            <v-text-field :rules="[required_rule]" v-model="password" type="password" autocomplete="new-password" outlined dense filled></v-text-field>
        <div class="d-flex align-center">
          <v-checkbox class="mt-0 mb-4" hide-details v-model="rememberMe">
            <template v-slot:label>
              Запомнить меня
            </template>
          </v-checkbox>
          <v-spacer />
          <router-link to="/login/reset">
            Забыли пароль?
          </router-link>
        </div>
        <div class="d-flex align-center">
          <span>Нет аккаунта? <router-link to="/register">Зарегистрироваться</router-link></span>
          <v-spacer/>
          <v-btn :loading="loading" type="submit" depressed color="primary" large class="ml-auto">Войти</v-btn>
        </div>
      </v-form>
      <v-dialog max-width="420px" content-class="white" v-model="dialog">
        <div v-text="dialog_content" class="pa-4" style="max-height: 420px; overflow-y: auto"></div>
        <v-btn @click="dialog = false" depressed>Закрыть</v-btn>
      </v-dialog>
    </div>
    <div class="c-container page" v-else>
      <p>Вы успешно авторизованы</p>
      <p v-if="redirect">Сейчас вы будете перенаправлены обратно...</p>
      <p v-else>Сейчас вы будете перенаправлены на главную...</p>
    </div>
  </div>
</template>

<script>
import opencity from "@/service/opencity";

export default {
  name: "Register",
  metaInfo: {
    title: 'Вход'
  },
  data() {
    return {
      email: "",
      password: "",
      rememberMe: true,
      required_rule: val => !!val || "Обязательное поле",
      email_rule: val => /.+@.+/.test(val) || "Неверный формат",
      loading: false,
      dialog: false,
      dialog_content: null,
      success: false
    }
  },
  computed: {
    redirect() {
      let raw = this.$route.query?.redirect;
      if (Array.isArray(raw)) raw = raw[0];
      if (raw) return decodeURI(raw);
      return null;
    }
  },
  created() {
    if (opencity.authenticated) {
      this.success = true;
    }
  },
  methods: {
    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      try {
        this.loading = true
        const response = await opencity.login(this.email, this.password, this.rememberMe)
        this.success = true

        setTimeout(() => {
          this.$router.push(this.redirect || "/")
        }, 3000)

      } catch (e) {
        this.dialog = true
        this.dialog_content = e
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
