import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import ru from 'vuetify/lib/locale/ru'
import Ripple from 'vuetify/lib/directives/ripple';

Vue.use(Vuetify, {
  directives: {
    Ripple,
  },
});

export default new Vuetify({
  breakpoint: {
    mobileBreakpoint: 'sm'
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        // primary: '#007BFF',
        // primary: '#0682BE',
        primary: '#a83a20',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        anchor: '#a83a20'
      }
    }
  },
  lang: {
    locales: { ru },
    current: 'ru'
  }
})
