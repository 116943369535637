<template>
  <div class="c-container page" v-if="loading">
    Подождите...
  </div>
  <div class="c-container page" v-else-if="success">
    Регистрация успешно завершена!
    <div class="d-flex pt-10 mx-n2">
      <v-btn large class="mx-2" to="/">На главную</v-btn><v-btn :to="'/users/' + myId" color="primary" v-if="myId" large class="mx-2">Мой профиль</v-btn>
    </div>
  </div>
  <div class="c-container page" v-else>
    Произошла ошибка
  </div>
</template>

<script>
import opencity from "@/service/opencity";

export default {
  name: "ConfirmRegistration",
  data() {
    return {
      loading: true,
      myId: null
    }
  },
  async created() {
    if (this.$route.query.success) {
      this.loading = false;
      return;
    };
    const token = this.$route.query.token
    const response = await opencity.post("/register/confirm", {
      token
    })
    if (response.ok) {
      await opencity._updateAuthCredentials((await response.json()).data, true);
      await this.$router.replace("?success=true")
      this.myId = opencity.userObject.id;
      this.loading = false
    } else {
      this.loading = false
    }
  },
  computed: {
    success() {
      return this.$route.query.success
    }
  }
}
</script>

<style scoped>

</style>
