<template>
  <div>
    <div class="banner-block">
      <div class="banner-block-body">
        <div style="min-height: 420px" class="banner-card c-container d-flex align-end">
          <div>
            <div class="text-start my-2 white--text">
              <span class="promo-text">Узнавайте о зеленом фонде города<br> и помогайте его развитию</span>
            </div>
            <v-btn v-if="$vuetify.breakpoint.mobile" large color="white" class="font-weight-bold success--text" to="/objects/map">Открыть карту</v-btn>
            <v-btn v-else x-large color="white" class="success--text font-weight-bold rounded-lg mt-4" elevation="0" to="/objects/map">
              <v-icon left>mdi-map-search</v-icon>
              Открыть карту города
            </v-btn>
          </div>
          <v-spacer />
          <div class="justify-center align-end mt-8 d-md-flex d-none">
            <v-sheet class="rounded-lg pa-2 px-3">
              <div class="stat-sheet__value success--text">23&nbsp;756</div>
              <div class="stat-sheet__title">объектов на карте</div>
            </v-sheet>
          </div>
        </div>
      </div>
    </div>
    <div class="pa-5 white" >
      <v-form @submit="search">
        <v-text-field height="44px" v-model="searchText" hide-details solo flat :append-icon="searchText.length > 3 ? 'mdi-arrow-right' : null" @click:append="search" style="max-width: 600px; margin: auto" color="primary" prepend-inner-icon="mdi-magnify" :placeholder="$vuetify.breakpoint.mobile ? 'Найти объект...' : 'Найти объект по названию или адресу...'" outlined dense></v-text-field>
      </v-form>
    </div>
    <div class="py-3 white">
      <div class="c-container">
        <v-tabs v-model="currentTab" background-color="transparent" color="black" centered>
          <v-tab>Уникальные объекты</v-tab>
          <v-tab>Завершенные работы</v-tab>
          <v-tab>Решенные проблемы</v-tab>
        </v-tabs>
      </div>
      <div class="c-container overflow-x-auto overflow-y-visible py-4">
        <div v-if="currentTab === 0" class="d-flex mx-n2">
          <router-link v-ripple :to="'/objects/' + object.id" class="item-card justify-stretch d-flex flex-column invisible-link overflow-hidden mx-2" v-for="object in cards" :key="object.id">
            <div class="item-card__cover">
              <img :src="object.cover_src || '/uploads/no-image.png'">
            </div>
            <div class="px-3 py-4 d-flex flex-grow-1 flex-column">
              <span class="font-weight-medium">{{ object.title }}</span>
              <p>
                {{ object.location }}
              </p>
              <v-spacer/>
              <div class="">{{ object.category.name }}</div>
            </div>
          </router-link>
        </div>
        <div v-else class="text--secondary ma-auto my-4 text-center">
          Ничего не найдено
        </div>
      </div>
      <div class="d-flex justify-center py-2">
        <router-link class="text-center" to="/objects/map">Смотреть все объекты</router-link>
      </div>
    </div>
    <div>
      <div class="c-container py-6 pb-3">
        <div class="d-flex">
          <span class="text-h5 font-weight-bold">Новости</span>
          <v-spacer/>
          <router-link to="/news">Все новости</router-link>
        </div>
        <div v-if="!$vuetify.breakpoint.mobile" class="d-flex align-stretch ma-n3 py-4">
          <router-link v-ripple v-if="news[0]" :to="'/news/' + news[0].id" class="invisible-link news-card col-7 rounded-lg pa-4 ma-3 px-6">
              <span class="font-weight-medium text-h6">{{ news[0].title }}</span>
              <div class="d-flex align-stretch my-2">
                <img class="rounded-lg" style="width: 36%; object-fit: cover" :src="news[0].cover">
                <div class="pl-4">
                  <div class="text--secondary text-body-2">{{ news[0].published_date }}</div>
                  <div class="text-body-2">{{ news[0].summary }}</div>
                </div>
              </div>
          </router-link>
          <div class="d-flex flex-column col-5 pa-0">
            <router-link v-ripple v-if="news[1]" :to="'/news/' + news[1].id" class="ma-3 invisible-link news-card rounded-lg pa-4 px-6 flex-grow-1">
              <div class="text--secondary text-body-2">{{ news[1].published_date }}</div>
              <div class="text-body-2">{{ news[1].title }}</div>
            </router-link>
            <router-link v-ripple v-if="news[2]" :to="'/news/' + news[2].id" class="ma-3 invisible-link news-card rounded-lg pa-4 px-6 flex-grow-1">
              <div class="text--secondary text-body-2">{{ news[2].published_date }}</div>
              <div class="text-body-2">{{ news[2].title }}</div>
            </router-link>
          </div>
        </div>
        <div v-else>
          <router-link v-for="article in featuredNews" :key="article.id" :to="'/news/' + article.id" class="invisible-link news-card rounded-lg pa-2 my-4 px-3">
            <div class="d-flex align-stretch my-1">
              <img class="rounded-lg" style="width: 36%; min-height: 96px; object-fit: cover" v-if="article.cover" :src="article.cover">
<!--              <span class="font-weight-medium text-h6">{{ news[0].title }}</span>-->

              <div class="pl-4">
                <div class="text--secondary text-body-2">{{ article.published_date }}</div>
                <div class="text-body-2">{{ article.title }}</div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="white">
      <div class="c-container py-6 pb-3">
        <div class="d-flex">
          <span class="text-h5 font-weight-bold">О проекте</span>
          <v-spacer/>
          <router-link to="/about">Подробнее</router-link>
        </div>
        <div class="py-4 d-flex flex-sm-nowrap flex-wrap">
          <div class="mb-6">
            Портал “Зеленый город” — это самая полная и актуальная информация об объектах Зеленого фонда города – парках, скверах, городских лесах, особо охраняемых природных территориях и о деревьях на территории Перми. С помощью портала вы можете узнать о природе города, о различных характеристиках зеленых насаждений и об их санитарном состоянии (от тех, что растут вдоль улиц, до тех, что находятся в ландшафтных зонах парков).          </div>
          <img height="180px" style="margin-left: 32px;" src="@/assets/About project.jpg">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import data, {fetchNews} from "@/data";

export default {
  name: 'Home',

  data() {
    return {
      news: [],
      currentTab: 0,
      searchText: ''
    }
  },

  async created() {
    this.news = await fetchNews()
  },

  components: {
    // HelloWorld
  },
  computed: {
    cards() {
      return data.objects
    },
    featuredNews() {
      return this.news?.slice(0, 3);
    }
  },
  methods: {
    search() {
      const query = this.searchText;
      if (query.length < 4) return;
      this.$router.push('/objects/map?q=' + query)
    },
  }
}
</script>

<style lang="scss">

.invisible-link {
  text-decoration: none !important;
  color: inherit !important;
}
</style>

<style lang="scss" scoped>

.promo-text {
  background-color: #00000055;
  padding-inline: 8px 8px;
  margin-inline-start: -8px;
  box-decoration-break: clone;

  line-height: 2.4rem;
  font-size: 2rem;
  font-weight: 500;

  @media (max-width: 960px) {
    line-height: 2rem;
    font-size: 1.5rem;
    font-weight: 500;
  }
}

.news-card {
  background-color: white;
  display: block;
  overflow: hidden;
  position: relative;
  &:after {
    background-color: currentColor !important;
    transition: color 0.2s cubic-bezier(0.4, 0, 0.6, 1);
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    opacity: 0.0;
    color: #313D4300;
  }
}
.news-card:hover {
  outline: thin  #313D4322 solid;

  &:after {
    //opacity: 0.06;
  }
}

.banner-block {
  background: rgb(229, 241, 221);
  // height: 320px;
  background: url("../../assets/image_1671783319.jpg") center;
  background-size: cover;
  background-attachment: fixed;
  background-origin: -20px 0px;
  position: relative;
  .banner-block-body {
    padding: 12px;
    backdrop-filter: blur(10px) brightness(1.1);
    //background: rgba(255,255,255,0.6);
    background: linear-gradient(0deg, rgba(255,255,255,1) 10%, rgba(255,255,255,0.75) 40%, rgba(255,255,255,0.75) 70%, rgba(255,255,255,1) 100%);
  }
  .banner-card {
    position: relative;
    box-shadow: 0 0px 12px rgba(0, 0, 0, 0.4);
    //background-image: linear-gradient(0deg, rgba(101,66,126,0.5) 0%, rgba(255,68,48,0.25) 50%, rgba(255,253,137,0) 100%),  url("../../assets/image_1671783319.jpg");
    background-image: linear-gradient(0deg, rgba(10,60,30,0.3) 0%, rgba(10,60,30,0.1) 50%, rgba(255,253,137,0) 100%),  url("../../assets/image_1671783319.jpg");
    background-position: center;
    padding: 24px;
    border-radius: 12px;
  }
}

.stat-sheet {
  border-radius: 16px 16px 0 0;
  min-width: 120px;
  max-width: 160px;
  min-height: 40px;
  padding: 8px 16px;
  margin: 0 8px;
}

.stat-sheet__value {
  font-weight: 600;
  font-size: 24px;
}

.stat-sheet__title {
  display: inline-block;
  line-height: normal;
}
</style>
