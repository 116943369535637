<template>
  <v-menu offset-y left nudge-bottom="8">
    <template #activator="{ on }">
      <OCMapButton v-on="on">
        <v-icon>mdi-dots-vertical</v-icon>
      </OCMapButton>
    </template>
    <v-sheet>
      <v-list dense>
        <slot name="prepend" />
        <v-list-item-group
          :value="preference"
          @change="select"
          color="primary"
          mandatory
        >
          <v-list-item v-for="(val, key) of mapStyles" :key="key" :value="key" v-text="val"></v-list-item>
        </v-list-item-group>
      </v-list>
    </v-sheet>
  </v-menu>
</template>
<script>
import OCMapButton from "@/components/map/OCMapButton";
import opencity from "@/service/opencity";

export default {
  name: 'OCMapSettings',
  components: {OCMapButton},
  data: () => ({
    preference: opencity.settings.mapStyle,
    mapStyles: opencity.getMapStyles(),
  }),
  methods: {
    select(style) {
      this.preference = style;
      opencity.saveMapStylePreference(style);
      this.map.setStyle(opencity.getDefaultMapStyle(), {
        diff: false
      });
    }
  },
  inject: ['map']
}
</script>
