<template>
  <div class="c-container page">
    <div class="d-flex">
      <div class="d-flex mb-2 align-center" style="flex: 1 1 50%">
        <span class="d-block  text--disabled font-weight-bold text-body-2">ОБЪЕКТ #{{item.id}}</span>
        <v-spacer></v-spacer>
        <v-btn small @click="like" shaped class="pr-2  ma-0 secondary-button" text>
          <v-icon size="24"   :color="item.likedByMe && 'red darken-1'" left>{{item.likedByMe ? 'mdi-heart' : 'mdi-heart-outline'}}</v-icon>
          <span :class="item.likedByMe && 'red--text text--darken-1'">{{ item.likeCount }}</span>
        </v-btn>
        <v-icon @click="activateSaveTo" :color="itemSaved && 'amber darken-1'" class="secondary-button mx-2" tabindex="0">
          {{itemSaved ? 'mdi-bookmark' : 'mdi-bookmark-outline'}}
        </v-icon>
      </div>
      <div class="info--sidebar pl-6 d-none d-md-block"></div>
    </div>
    <span v-if="item" class="text-h5 font-weight-bold" v-text="item.title"></span>
    <div v-if="item" class="d-flex my-4 align-start">
      <article v-if="!tab" style="flex: 1 1 50%">
        <div class="d-flex">
          <span class="d-block mb-4 text--secondary" v-text="item.location" />
          <v-spacer></v-spacer>
          <router-link :to="'/objects/map?item=' + id" >Показать на карте</router-link>
        </div>
        <div :style="{maxWidth: $vuetify.breakpoint.mobile ? '300px' : '490px'}">
          <OCMediaPreview
            carousel
            :media="item.media"
            :height="$vuetify.breakpoint.smAndDown ? 180 : 248"
          />
        </div>
        <v-tabs class="mb-3" background-color="transparent">
          <v-tab>Описание</v-tab>
<!--          <v-tab disabled>Как добраться</v-tab>-->
        </v-tabs>
        <ExpandableParagraph :strip-length="200" :text="item.note"></ExpandableParagraph>
        <div class="my-6">
          <template v-if="item.location">
            <div class="d-flex flex-wrap my-3">
              <div class="text--secondary" style="width: 30%; min-width: 200px">Адрес:</div>
              <div style="width: 70%; min-width: 200px">{{ item.location }}</div>
            </div>
            <v-divider/>
          </template>
          <template v-if="item.parentObject">
            <div class="d-flex flex-wrap my-3">
              <div class="text--secondary" style="width: 30%; min-width: 200px">Входит в состав:</div>
              <router-link :to="'/objects/' + item.parentObject.id" class="d-block" style="width: 70%; min-width: 200px">{{ item.parentObject.title }}</router-link>
            </div>
            <v-divider/>
          </template>
          <template v-for="(entry, i) in properties">
            <div class="d-flex flex-wrap my-3" :key="entry['name']">
              <div class="text--secondary" style="width: 30%; min-width: 200px">{{ entry["name"] }}:</div>
              <div style="width: 70%; min-width: 200px">
                <div class="d-flex align-center" :key="entry['value']">
                  <span v-if="entry['trait'] === 'species'" :style="{background: getTreeBackground(entry.value)}" class="tree-indicator mr-2"></span>
                  <div style="white-space: pre-line" v-text="entry['value']"></div>
                  </div>
              </div>
            </div>
            <v-divider :key="i"/>
          </template>
        </div>
      </article>
      <article v-else-if="tab === 'media' && item.media && item.media.length" style="flex: 1 1 50%">
        <template v-for="(group, value) in mediaGrouped">
          <div class="text-h5 text--secondary mt-md-5" v-if="value !== 'undefined'" :key="value" v-text="value + ':'"></div>
          <div class="d-flex flex-wrap mx-n2" :key="value + 'gallery'">
            <div v-for="(file, i) in group" :key="file.id" >
              <OCMediaPreview height="200"  class="ma-2" :counter="false" tile :fixedSlide="i" :media="group" ></OCMediaPreview>
            </div>
            <router-link tag="div" :to="'/objects/' + item.id + '/media/add'" style="width: 200px; height: 200px; margin: 16px 8px;">
              <add-photo-area></add-photo-area>
            </router-link>
          </div>
        </template>
      </article>
      <article  style="flex: 1 1 50%" v-else>
        <div class="text-center ma-auto my-5 mt-10 text--secondary">Ничего не найдено</div>
      </article>
      <div class="info--sidebar pl-6 d-none d-md-block">
<!--        <v-btn :href="routeLink" target="_blank" block class="mb-2 py-5" depressed color="blue" dark><v-icon  left>mdi-map-marker-path</v-icon> Маршрут</v-btn>-->
<!--        <v-btn block color="red" dark depressed class="mb-3 py-5">Сообщить о проблеме</v-btn>-->
        <v-sheet rounded class="overflow-hidden">
          <v-list dense>
            <v-list-item :to="'/objects/' + id" exact>Об объекте</v-list-item>
            <v-list-item :to="'/objects/' + id + '/media'" exact>
              Фото
              <v-spacer/>
            {{ item.media ? item.media.length : 0 }}
            </v-list-item>
            <v-list-item :to="'/objects/' + id + '/files'" exact>Документы</v-list-item>
            <v-list-item :to="'/objects/' + id + '/plans'" exact>Работы на объекте</v-list-item>
            <v-list-item :to="'/objects/' + id + '/problems'" exact>Сообщения</v-list-item>
          </v-list>
        </v-sheet>
        <ShareBlock />
      </div>
    </div>
    <SaveToDialog @save="onListsUpdate" :items="items" v-model="showSaveTo" />
  </div>
</template>

<script>
import {fetchNews} from "@/data";
import opencity, { toText } from "@/service/opencity";
import OCMediaPreview from "@/components/object/OCMediaPreview.vue";
import ShareBlock from "@/components/ShareBlock.vue";
import ExpandableParagraph from "@/components/ExpandableParagraph.vue";
import useAuth from "@/mixins/useAuth";
import SaveToDialog from "@/views/ServiceObjects/Map/SaveToDialog.vue";
import AddPhotoArea from "@/components/object/AddPhotoArea.vue";



const treeColors = {
  Дуб: "#386703",
  Берёза: "#B8E760",
  Тополь: "#7DA422",
  Липа: "#099922",
  Яблоня: "#B0C901",
  Клён: "#DD7C51",
  Груша: "#E38B1D",
  Слива: "#7ED6BB",
  Рябина: "#FA5C24",
  Ясень: "#60EBDA",
  Ирга: "#7BBCC2",
  Сирень: "#68A5E0",
  Лиственница: "#33BE91",
  Вяз: "#4D9D3B",
  Ива: "#76BF54",
  Сосна: "#91AA75",
  Можжевельник: "#4D9D3B",
  Ель: "#035505",
  Черемуха: "#2F8C9B",
  Туя: "#928720",
  Пихта: "#386703",
  Ольха: "#7DA422",
  Лох: "#7F7D6C",
  Облепиха: "#E0B745",
  Орех: "#DDAC60",
  Абрикос: "#F47919",
  Вишня: "#BD5015",
  Лавр: "#B0C901",
  Боярышник: "#A25425",
  Кедр: "#AA7241"
};

export default {
  name: "Info",
  components: {AddPhotoArea, SaveToDialog, ExpandableParagraph, ShareBlock, OCMediaPreview},

  metaInfo() {
    if (this.item) return {
      title: this.item.title,
      meta: this.item.media?.[0] && [
        {property: 'og:image', content: window.location.origin + opencity.getMediaUrl(this.item.media[0])},
      ]
    }
    return {}
  },

  data() {
    return {
      news: [],
      item: null,
      showSaveTo: false,
      itemSaved: false
    }
  },
  async created() {
    this.$watch(
      "id",
      val => {
        opencity.get("/lists?objectId=" + this.id).then(res => res.json()).then(data => {
          this.onListsUpdate(data);
        })
      },
      { immediate: true }
    );
  },
  mixins: [ useAuth ],
  methods: {
    like() {
      if (!opencity.authenticated) {
        this.$router.push(`/login?redirect=${ encodeURI(this.$route.fullPath) }`)
      }
      if (this.item?.likedByMe) {
        opencity.delete(`/objects/${this.id}/likes`).then(msg => {
          if (msg.ok) {
            this.item.likedByMe = false;
            this.item.likeCount -= 1;
          }
        });
      } else {
        opencity.post(`/objects/${this.id}/likes`).then(msg => {
          if (msg.ok) {
            this.item.likedByMe = true;
            this.item.likeCount += 1;
          }
        });
      }
    },
    onListsUpdate(data) {
      this.itemSaved = data.some(el => el.allIncluded);
    },
    activateSaveTo() {
      if (this.OCAuthenticated) {
        this.showSaveTo = true
      } else {
        this.$router.push(`/login?redirect=${ encodeURI(this.$route.fullPath) }`)
      }
    },
    getTreeBackground(genus) {
      return treeColors[genus];
    },
  },
  computed: {
    items() {
      return [this.item]
    },
    article() {
      return this.news.find(el => el.id == this.$route.params.id);
    },
    properties() {
      if (!this.item) return null;
      const props = this.item.data;
      const res = [];
      const type = opencity.getObjectType(this.item.objectType.id);
      const title = opencity.getParentCategory(type.id).title + " / " + type.title;
      res.push({
        name: "Категория / Тип объекта",
        value: title
      });
      for (let field of type.schema.fields) {
        let raw_value = props[field.key];
        if (!raw_value) continue;
        let name = field.name;
        let value = toText(field.type, raw_value);
        let match = name.match(/ \((\S+)\)$/);
        let measure = match?.[1];
        if (measure) {
          name = name.replace(match?.[0], "");
          value = value + " " + measure;
        }
        let trait;
        if (field.type === "genera") {
          trait = "species";
        }
        res.push({
          name,
          value,
          trait
        });
      }
      return res;
    },
    routeLink() {
      return "https://yandex.ru/maps/50/perm/?" + `&rtext=~${this.item.geometry?.coordinates[1]}%2C${this.item.geometry?.coordinates[0]}`
    },
    id() {
      return this.$route.params.id;
    },
    tab() {
      return this.$route.params?.tab;
    },
    mediaGrouped() {
      return this.item?.media.reduce((acc, file) => {
        if (acc[file.group]) {
          acc[file.group].push(file)
        } else {
          acc[file.group] = [file]
        }
        return acc;
      }, {});
    }
  },
  watch: {
    id: {
      async handler(val) {
        this.item = await opencity.getObject(val);
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.cover {
  max-width: 100%;
  max-height: 480px;
}

.info--sidebar {
  width: 340px;
  flex: 0 1 auto;

  .v-sheet {
    box-shadow: 0 0 8px rgb(0 0 0 / 8%);

    .v-list-item {
      text-transform: uppercase;
      font-weight: 500;
      opacity: 0.7;
      font-size: 14px;
    }

    a.v-list-item--active {
      opacity: 1;
    }

    a:hover {
      text-decoration: none;
    }
  }

  position: sticky;
  top: 12px;
}

article {
  word-break: break-word;


  ::v-deep h2 {
    font-weight: 500;
    margin: 8px 0;
  }
}
</style>
