<template>
  <div class="d-flex align-center justify-center text--disabled px-2 py-4">
    <span class="mx-3">Поделиться </span>
    <v-btn icon class="mx-1 share-button" :href="shareVk" target="_blank">
      <v-icon size="32">mdi-vk</v-icon>
    </v-btn>
    <v-btn icon class="mx-1 share-button" :href="shareTg" target="_blank">
      <v-icon size="36">mdi-telegram</v-icon>
    </v-btn>
    <v-btn icon class="mx-2 share-button">
      <v-icon @click="shareCopy" size="32">{{ copied ? 'mdi-check' : 'mdi-content-copy'}}</v-icon>
    </v-btn>
  </div>
</template>

<script>

export default {
  name: "ShareBlock",
  data () {
    return {
      copied: false
    }
  },
  methods: {
    shareCopy() {
      navigator.clipboard.writeText(location.href).then(() => {
        this.copied = true;
      });
    }
  },
  computed: {
    shareVk() {
      const { metaInfo } =this.$meta().refresh()
      const ogImage = metaInfo.meta.find(el => el.property === 'og:image')?.content
      return `https://vk.com/share.php?url=${encodeURI(location.href)}&title=${metaInfo.title}&image=${ogImage}`
    },
    shareTg() {
      const { metaInfo } =this.$meta().refresh()
      return `https://t.me/share/url?url=${encodeURI(location.href)}&text=${metaInfo.title}`
    }
  },
  watch: {
    '$route'() {
      this.copied = false
    }
  }
}
</script>

<style lang="scss" scoped>
.share-button {
  opacity: 0.5;
  &:hover {
  opacity: 0.7;
   }
}
</style>
