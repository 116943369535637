<template>
  <div class="c-container page">
    <span class="text-h5 font-weight-medium">Новости в Перми</span>
    <div class="d-flex my-4 align-start">
      <div style="flex: 1 1 50%">
<!--        <v-text-field outlined solo prepend-inner-icon="mdi-magnify" flat color="blue" dense></v-text-field>-->
        <div v-for="article in news" :key="article.id" class="my-4">
          <router-link :to="'/news/' + article.id"><span style="color: #313D43" class="text-h6">{{ article.title }}</span></router-link>
          <div class="d-flex align-stretch my-2">
            <div >
              <img v-if="article.cover" class="mr-4 rounded" style="max-width: 40%; object-fit: cover; float: left" :src="article.cover">
              <div class="text--secondary text-body-2">{{ article.published_date }}</div>
              <div class="text-body-2">{{ article.summary }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="info--sidebar pl-6 d-md-block d-none">
<!--        <v-btn block color="blue" dark depressed class="mb-3 py-5">Подписаться на новости</v-btn>-->
        <v-sheet rounded class="overflow-hidden">
          <v-list dense>
            <v-list-item to="/news" exact>Все новости</v-list-item>
<!--            <v-list-item exact>ГАЗЕТА “ЗДРАВСТВУЙ!”</v-list-item>-->
            <v-list-item exact>Избранное</v-list-item>
          </v-list>
        </v-sheet>
        <ShareBlock />
      </div>
    </div>
  </div>
</template>

<script>
import { fetchNews } from "@/data";
import ShareBlock from "@/components/ShareBlock.vue";

export default {
  name: "Info",
  components: {ShareBlock},

  metaInfo: {
    title: 'Новости'
  },

  data() {
    return {
      news: []
    }
  },

  created() {
    fetchNews().then(res => this.news = res);
  },

  computed: {
  }
}
</script>

<style lang="scss" scoped>
.news-card {
  box-shadow: 0 0 8px rgb(0 0 0 / 8%);
}

.info--sidebar {
  width: 340px;
  flex: 0 1 auto;
  .v-sheet {
    box-shadow: 0 0 8px rgb(0 0 0 / 8%);
    .v-list-item {
      text-transform: uppercase;
      font-weight: 500;
      opacity: 0.7;
      font-size: 14px;
    }
    a.v-list-item--active {
      opacity: 1;
    }
    a:hover {
      text-decoration: none;
    }
  }
  position: sticky;
  top: 12px;
}
</style>
