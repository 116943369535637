var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.media && _vm.media.length && _vm.carousel)?_c('v-carousel',{staticClass:"my-4 rounded",attrs:{"hide-delimiters":"","show-arrows":_vm.media.length > 1 || (_vm.media.length > 0 && _vm.allowAdd),"show-arrows-on-hover":"","touch":{
      left: () => _vm.$refs.carousel.next(),
      right: () => _vm.$refs.carousel.prev()
    },"height":_vm.height},model:{value:(_vm.currentSlide),callback:function ($$v) {_vm.currentSlide=$$v},expression:"currentSlide"}},[_vm._l((_vm.media),function(cur,i){return _c('v-carousel-item',{key:cur.id || cur.src,staticClass:"grey lighten-2",staticStyle:{"cursor":"pointer"},attrs:{"src":_vm.imagePreviewUrl(i)},on:{"click":function($event){return _vm.openGallery(i)}}})}),(_vm.allowAdd)?_c('v-carousel-item',{key:"last",on:{"click":function($event){return _vm.$emit('add')}}},[_c('AddPhotoArea')],1):_vm._e(),_c('v-sheet',{staticClass:"rounded pa-1 d-flex align-center elevation-0",staticStyle:{"position":"absolute","right":"8px","bottom":"8px","background-color":"#00000088"},attrs:{"dark":""}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-camera-outline")]),_c('span',{staticClass:"text-body-2",domProps:{"textContent":_vm._s(
          (_vm.currentSlide === _vm.media.length
            ? '+'
            : _vm.currentSlide + 1) + ' / ' + _vm.media.length
        )}})],1)],2):(_vm.media && _vm.media.length)?_c('v-img',{staticClass:"my-4 rounded grey lighten-2",staticStyle:{"cursor":"pointer"},attrs:{"contain":_vm.contain,"width":_vm.tile ? _vm.height : undefined,"height":_vm.height,"src":_vm.imagePreviewUrl(_vm.currentSlide)},on:{"click":function($event){return _vm.openGallery(_vm.currentSlide)}}},[(_vm.counter)?_c('v-sheet',{staticClass:"rounded pa-1 d-flex align-center elevation-0",staticStyle:{"position":"absolute","right":"8px","bottom":"8px","background-color":"#00000088"},attrs:{"dark":""}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-camera-outline")]),_c('span',{staticClass:"text-body-2",domProps:{"textContent":_vm._s(_vm.media.length)}})],1):_vm._e()],1):(_vm.allowAdd)?_c('AddPhotoArea',{style:({ height: _vm.height + 'px' }),on:{"click":function($event){return _vm.$emit('add')}}}):_c('NoPhoto',{staticClass:"my-4",attrs:{"height":_vm.height}})
}
var staticRenderFns = []

export { render, staticRenderFns }