<template>
  <div class="d-flex align-center flex-column w-100">
    <span id="stage-2" class="text-h5 font-weight-bold my-6 my-md-8"
      >1. Фото дерева</span
    >
    <div
      class="
            warning-block
            font-weight
            px-4
            py-3
            px-md-8
            py-md-6
            d-flex
            align-center
          "
    >
      <v-icon color="warning" class="mr-4 flex-shrink-0 rounded" size="32">
        mdi-alert
      </v-icon>
      Обязательно добавьте фотографии дерева
    </div>
    <MediaUploadGallery v-show="!showUploadArea" v-model="files" ref="input" parentClass="row my-2" childClass="col col-4 col-sm-3 pa-1 pa-sm-2" />
    <label v-if="showUploadArea" class="d-block w-100 mt-2" for="files" tabindex="0">
      <div
        @drop.prevent="onDrop"
        @dragover.prevent
        class="drop-area-main drop-area d-flex flex-column justify-center align-center"
      >
        <v-icon size="48" style="opacity: 0.5"> mdi-camera </v-icon>
        <span
          class="text-center mt-2 d-none d-md-block"
          style="max-width: 340px"
        >
          Перетяните сюда фото или
          <span class="text-decoration-underline"
          >загрузите со своего носителя</span
          >
        </span>
        <span class="text-center mt-1 d-md-none text-decoration-underline"
        >Загрузить фото</span
        >
        <span class="text--secondary text-body-2 text-center mt-1">
          PNG, JPEG, не больше 10 Мб
        </span>
      </div>
    </label>
  </div>
</template>

<script>
import MediaUploadGallery from "@/components/object/MediaUploadGallery.vue";

export default {
  components: { MediaUploadGallery },
  props: {
    stage: {
      type: Number
    },
    value: {
      type: Object
    }
  },
  data() {
    return {
      files: []
    };
  },
  methods: {
    onDrop() {
      this.$refs.input.onDrop(...arguments);
    },
  },
  computed: {
    showUploadArea() {
      return !this.files.map(el => el).length;
    },
  },
  created() {
    this.files = this.value.media || [];
    this.value.media = this.files;
  },
};
</script>

<style>
.pswp__img {
  object-fit: contain;
}
</style>

<style lang="scss" scoped>
.drop-area-main {
  width: 100%;
  padding: 8px;
  height: 236px;
  @media (min-width: 960px) {
    height: 400px;
  }
}

.drop-area {
  border: 2px dashed #26323899;
  //background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%230000009C' stroke-width='4' stroke-dasharray='2%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  opacity: 0.8;
  &:hover {
    opacity: 1;
    border-color: #263238bb;
    background-color: rgba(0,0,0,0.05);
  }
}

</style>
