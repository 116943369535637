import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home/index.vue'
import OvjectsLanding from '../views/ServiceObjects/Landing'
import PlansLanding from '../views/ServicePlans/Landing'
import ProblemsLanding from '../views/ServiceProblems/Landing'
import ObjectsMap from '../views/ServiceObjects/Map'
import Info from "@/views/Info.vue";
import News from "@/views/News.vue";
import NewsArticle from "@/views/NewsArticle.vue";
import ObjectPage from "@/views/Object.vue";

import AddTree from "@/views/ServiceObjects/NewObject/DemoIssueStepper.vue";
import Register from "@/views/Register.vue";
import ConfirmRegistration from "@/views/ConfirmRegistration.vue";
import Login from "@/views/Login.vue";
import UserProfile from "@/views/User/UserProfile.vue";
import VueMeta from "vue-meta";
import UserSettings from "@/views/User/UserSettings.vue";
import Analytics from "@/views/ServiceObjects/Analytics/Analytics.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import ForgotPasswordConfirm from "@/views/ForgotPasswordConfirm.vue";
import AddPhotos from "@/views/ServiceObjects/AddPhotos.vue";
import UserData from "@/views/User/UserData.vue";

Vue.use(VueMeta)
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home
  },
  {
    path: '/objects',
    name: 'ObjectsLanding',
    component: OvjectsLanding
  },
  {
    path: '/objects/map',
    name: 'ObjectsMap',
    component: ObjectsMap
  },
  {
    path: '/objects/analytics',
    name: 'Analytics',
    component: Analytics
  },
  {
    path: '/objects/add',
    name: 'AddObject',
    component: AddTree
  },
  {
    path: '/objects/:id',
    name: 'Object',
    component: ObjectPage
  },
  {
    path: '/objects/:id/media/add',
    name: 'AddPhoto',
    component: AddPhotos
  },
  {
    path: '/objects/:id/:tab',
    name: 'Object',
    component: ObjectPage
  },
  {
    path: '/plans',
    name: 'Plans',
    component: PlansLanding
  },
  {
    path: '/problems',
    name: 'Problems',
    component: ProblemsLanding
  },
  {
    path: '/about',
    component: Info
  },
  {
    path: '/about/:id',
    component: Info
  },
  {
    path: '/info',
    component: Info
  },
  {
    path: '/info/:id',
    component: Info
  },
  {
    path: '/news',
    component: News
  },
  {
    path: '/news/:id',
    component: NewsArticle
  },
  {
    path: '/login/reset/confirm',
    component: ForgotPasswordConfirm
  },
  {
    path: '/login/reset',
    component: ForgotPassword
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/register',
    component: Register
  },
  {
    path: '/register/confirm',
    component: ConfirmRegistration
  },
  {
    path: '/users/:id',
    component: UserProfile
  },
  {
    path: '/users/:id/data',
    component: UserData
  },
  {
    path: '/settings',
    component: UserSettings
  }
]

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    if (err.type === VueRouter.NavigationFailureType.duplicated) {
      return;
    }
    throw err;
  });
};

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.path == from.path) {
      return null;
    }
    if (savedPosition) {
      return savedPosition;
    } else {
      return { y: 0, x: 0 };
    }
  }
})

export default router
