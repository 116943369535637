<template>
  <v-sheet
    style="margin: auto;"
    @touchstart.prevent="$emit('open')"
    class="info-panel-header pt-4 map-bottom-sheet-collapsed"
  >
    <template v-if="item">
      <OCObjectListItem style="height: inherit" :item="currentItem" />
    </template>
    <span v-else-if="selectedItems.length" class="text-h6 mx-4 py-2">
      Выбрано объектов: {{ selectedItems.length }}
    </span>
    <span v-else class="text-h6 mx-4 py-2 d-block">Объекты: {{ totalItems }}</span>
  </v-sheet>
</template>
<script>
import OCObjectListItem from "@/components/object/OCObjectListItem"

export default {
  name: 'OCMapBottomSheetCollapsed',
  components: {OCObjectListItem},
  props: {
    currentItem: {},
    item: {},
    selectedItems: {},
    totalItems: {}
  },
  updated() {
    this.$emit('update:height', this.$el?.clientHeight)
  }
}
</script>
<style lang="scss">

$mobilePaddingTop: 48px;

.map-bottom-sheet-collapsed {
  .v-list-item__title {
    font-weight: 500;
    white-space: normal;
  }
}


.info-panel-header {
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  position: relative;

  .theme--dark & {
    --sheet-handle-color: rgba(80, 80, 80, 1);
  }
  --sheet-handle-color: rgba(200, 200, 200, 1);

  &:after {
    opacity: 1;
    content: " ";
    height: 4px;
    border-radius: 2px;
    width: 12px;
    background: var(--sheet-handle-color);
    position: absolute;
    top: 12px;
    transform: translate(-12%) rotate(25deg);
    left: 50%;
  }

  &:before {
    opacity: 1;
    content: " ";
    height: 4px;
    border-radius: 2px;
    width: 12px;
    background: var(--sheet-handle-color);
    position: absolute;
    top: 12px;
    transform: translate(-88%) rotate(-25deg);
    left: 50%;
  }

  z-index: 1;
  background: white;
  min-height: 72px;
  //padding-bottom: 42px;
}

</style>
