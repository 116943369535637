<template>
  <div class="demo-issue-stepper">
    <div class="center-container d-flex flex-column" v-if="!newObjId">
      <div class="d-flex align-center py-4 pt-md-8 pb-md-4 demo-scroll">
        <span
          class="text-uppercase font-weight-medium"
          style="opacity: 0.5; font-size: 14px"
        >Добавить фото</span
        >
        <v-spacer />
        <TextButton icon class="ml-4" color="#414249" @click="exit">
          <v-icon>$close</v-icon>
        </TextButton>
      </div>
      <v-sheet
        class="
        center-card
        d-flex
        flex-column
        align-center
      "
      >
        <v-divider style="border-width: 1px" class="w-100"></v-divider>
        <div class="w-100 my-3">Фото будет привязано к объекту <router-link target="_blank" :to="'/objects/' + objectId">{{ object ? object.title : '...' }}</router-link></div>
        <add-issue-media-step
          id="stage-2"
          :value="product"
          ref="fileUpload2"
        />
        <v-alert v-if="anyFileUploadErrors" class="w-100 rounded-lg my-3" type="error">
          <div class="">
            Возникли ошибки при загрузке файлов
            <v-btn depressed light @click="retryFileUpload" class="ml-3 float-end">Попробовать снова</v-btn>
          </div>
        </v-alert>

      </v-sheet>
      <v-spacer></v-spacer>
    </div>
    <div
      class="center-container d-flex flex-column justify-center"
      key="unique"
      v-if="newObjId"
    >
      <v-sheet
        class="
        center-card
        my-0 my-md-5
        d-flex
        flex-column
        align-center
      "
      >
        <span class="text-h6 text-center font-weight-bold"
        >Спасибо за добавление данных!
        </span>
        <div class="d-flex mt-10 mb-2 mb-md-10">
          <v-btn
            class="mr-1 mx-md-2"
            outlined
            color="secondary"
            :min-width="$vuetify.breakpoint.mdAndUp ? '200' : '140'"
            :min-height="$vuetify.breakpoint.mdAndUp ? '48' : '40'"
            to="/"
          >На главную</v-btn
          >
          <v-btn
            class="ml-1 mx-md-2"
            color="primary"
            depressed
            :min-width="$vuetify.breakpoint.mdAndUp ? '200' : '140'"
            :min-height="$vuetify.breakpoint.mdAndUp ? '48' : '40'"
            :to="'/objects/' + objectId"
          >Перейти к объекту</v-btn
          >
        </div>
      </v-sheet>
    </div>
    <div class="action-row action-row--shadow d-flex justify-center px-4 my-3 my-md-5 py-3 background mx-n4" v-if="!newObjId">
      <v-btn
        @click="exit"
        class="mr-1 mx-md-2"
        outlined
        color=""
        :min-width="$vuetify.breakpoint.mdAndUp ? '200' : '140'"
        :min-height="$vuetify.breakpoint.mdAndUp ? '48' : '40'"
      >Отмена</v-btn
      >
      <v-btn
        :loading="loading"
        :disabled="!allowedToProceed"
        @click="sendObject"
        class="ml-1 mx-md-2"
        color="primary"
        depressed
        :min-width="$vuetify.breakpoint.mdAndUp ? '200' : '140'"
        :min-height="$vuetify.breakpoint.mdAndUp ? '48' : '40'"
      >Отправить</v-btn>
    </div>
  </div>
</template>

<script>
import TextButton from "./NewObject/TextButton.vue";
import AddIssueMediaStep from "./AddIssueMediaStep.vue";

import opencityService from "@/service/opencity";

import PhotoSwipe from "photoswipe";
import "photoswipe/dist/photoswipe.css";
import opencity from "@/service/opencity";
import useAuth from "@/mixins/useAuth";
import categories from "@/assets/categories";
import MediaUploadGallery from "@/components/object/MediaUploadGallery.vue";

export default {
  name: "AddPhotoView",
  metaInfo: {
    title: 'Добавить фото'
  },
  data: () => ({
    product: null,
    dialog_leave: false,
    lockProceed: false,
    loading: false,
    newObjId: null,
    showMap: false,
    object: null
  }),
  mixins: [
    useAuth
  ],
  methods: {
    retryFileUpload() {
      let fu = this.$refs.fileUpload2?.$refs?.input;
      fu.retryAll();
    },
    async sendObject() {
      if (this.loading) return;
      this.loading = true;
      const media = this.product.media.map(el => el.realId);
      try {
        await opencityService.post('/objects/' + this.objectId + '/media', media);
        this.newObjId = this.objectId;
      } finally {
        this.loading = false;
      }
    },
    exit() {
      if (window.history.length === 0) {
        this.$router.push({ path: "/" });
      } else {
        this.$router.back();
      }
    },
    reset() {
      this.newObjId = null;
      this.product = {
        media: [],
      };
    }
  },
  computed: {
    objectId() {
      return this.$route.params.id;
    },
    allowedToProceed() {
      return (
        this.product?.media.length &&
        !this.product?.media?.some(el => el.uploading || el.error)
      );
    },
    anyFileUploadErrors() {
      return this.product?.media?.some(el => el.error);
    },
  },
  created() {
    this.reset();
  },
  watch: {
    OCAuthenticated: {
      immediate: true,
      handler(val) {
        if (!val)
          this.$router.push(`/login?redirect=${ encodeURI(this.$route.fullPath) }`)
      }
    },
    objectId: {
      immediate: true,
      handler(val) {
        opencity.getObject(val).then(object => {
          this.object = object;
        })
      }
    }
  },
  components: {
    // MediaUploadGallery,
    TextButton,
    // AddIssueCategoryStep,
    // AddIssueTopicStep,
    AddIssueMediaStep,
    // AddIssueMapStep,
    // AddIssueDescriptionStep
  }
};
</script>

<style lang="scss">
.gallery-element {
  width: 120px;
  @media (min-width: 920px) {
    width: 140px;
  }
}

.text-h5 {
  text-align: center;
}

.v-text-field__details {
  padding: 4px 0 !important;
}

.w-100 {
  width: 100%;
}

.v-dialog.dialog {
  border-radius: 0;
  max-width: 624px;
  width: 100%;
}

.v-btn {
  // text-transform: none;
  letter-spacing: normal;
}

.warning-block {
  position: relative;
  width: 100%;
  z-index: 0;
  font-size: 12px;
  line-height: 18px;
  padding-top: 8px;
  padding-bottom: 8px;
  color: #955205;

  // margin: 16px 0;
  @media (min-width: 960px) {
    font-size: 14px;
    padding-top: 12px;
    padding-bottom: 12px;
    // margin: 24px 0 16px 0;
  }
  &:before {
    z-index: -1;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--v-warning-base);
    opacity: 0.15;
    position: absolute;
  }
}

.medium-font {
  line-height: 18px;
  font-size: 15px;
  @media (min-width: 960px) {
    line-height: 24px;
    font-size: 18px;
  }
}

.large-font {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0em;
  @media (min-width: 960px) {
    font-size: 24px;
    line-height: 32px;
  }
}
</style>

<style scoped lang="scss">
.v-input--is-label-active .private-checkbox-label {
  opacity: 1;
}

.private-checkbox-label {
  opacity: 0.7;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1) opacity;
  &:hover {
    opacity: 1;
  }
}

.background {

}

.map-mock {
  // background-image: url("../assets/map.png");
  background-size: cover;
  height: 400px;
}

//.demo-scroll {
//   position: sticky;
//   top: 0;
//   background: #ececed;
//   z-index: 1;
//}

h3 {
  margin: 40px 0 0;
}

.center-container {
  max-width: 720px;
  margin: auto;
  width: 100%;
  min-height: 100vh;
  .center-card {
    // padding: 24px 16px;
    min-height: 100%;
    @media (min-width: 960px) {
      // padding: 56px 110px 40px 110px;
    }
  }
}

.demo-issue-stepper {
  // background: #ececed;
  min-height: 100vh;
  width: 100%;
  padding: 0 16px;
  background-color: white;
}

.theme--dark .demo-issue-stepper {
  background-color: #1e1e1e;
}

.upload-thumbnail {
  width: 144px;
  height: 144px;
  margin: 0px 6px;
  cursor: zoom-in;
  flex: 0 0 auto;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.background {
  background: white;
}

.theme--dark .background {
  background: #1e1e1e;
}

.action-row {
  position: sticky;
  //border-radius: 12px 12px 0 0;
  bottom: 0;
  //border-top: thin solid currentColor;
  z-index: 100;
}

.action-row--shadow {
  //border-top: 1px solid;
  //border-image: linear-gradient(
  //    to left,
  //    rgba(0, 0, 0, 0),
  //        rgba(0, 0, 0, 0),
  //    currentColor,
  //        rgba(0, 0, 0, 0),
  //
  //    rgba(0, 0, 0, 0)
  //  )
  //  1;
}

//::v-deep .gallery-grid {
//  display: grid;
//  justify-content: center;
//  grid-template-columns: repeat(6, calc(16.6% - 13.3px));
//  grid-gap: 16px;
//}
</style>
