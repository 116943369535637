import opencity from "@/service/opencity";

export default {
  data: () => ({
   OCUserObject: opencity.userObject, OCAuthenticated: opencity.authenticated
  }),
  created() {
    opencity.on("update:auth", res => {
      this.OCAuthenticated = res;
      this.OCUserObject = opencity.userObject;
    });
  }
};
