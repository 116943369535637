<template>
  <MglMap
    :zoom="zoom"
    :center="coords"
    :access-token="mapAccessToken"
    :map-style="mapStyle"
    height="100"
    @load="onMapLoad2"
    v-on="{ 'style.load': onMapLoad2 }"
    @click="onMapClick"
    @mousemove="onMapMouseMove"
    @mouseout="onMapMouseLeave"
    @zoom="setZoom"
    @move="onDrag"
    @zoomend="setZoom"
    :class="mapClass"
    ref="map"
    :max-bounds="bounds"
  >
    <OCLayerManager
      @hook:mounted="mounted = true"
      ref="dataLayerManager"
      :activeItemIds="activeItems || []"
      :options="options"
      :highlight="highlight"
      v-if="!options.hideFeatures"
    />

    <MglMarker
      :coordinates="markerLatLng"
      @update:coordinates="updatePoint($event[1], $event[0])"
      draggable
      anchor="bottom"
      v-if="markerLatLng"
    />

    <div class="map-controls">
      <slot />
      <v-icon
        v-if="!objectsVisible"
        class="click-through"
        style="position: absolute; top: calc(50% - 12px); left: calc(50% - 12px); font-size: 24px; color:black; text-shadow: white 0 0 2px"
      >mdi-plus
      </v-icon>
      <div
        v-if="!objectsVisible"
        class="map-controls__bottom d-flex justify-center pa-2 pa-md-4"
        :class="$slots['controls-bottom'] ? 'map-controls__bottom-offset' : ''"
      >
        <v-btn @click="forceZoom(requiredZoom)" color="primary" large
        >Приблизьте для уточнения</v-btn
        >
      </div>
      <div class="map-controls__zoom d-flex flex-column pa-1 pa-md-2">
        <div class="d-flex controls-container">
          <slot name="controls-top" />
        </div>
        <div class="d-flex flex-grow-1">
          <div class="d-flex flex-column controls-container">
            <slot name="controls-left" />
          </div>
          <v-spacer />
          <div class="d-flex flex-column controls-container">
            <slot name="controls-right" />
          </div>
        </div>
        <div class="d-flex controls-container">
          <slot name="controls-bottom" />
        </div>
      </div>
    </div>
  </MglMap>
</template>

<script>
import { MglMap, MglMarker } from "vue-mapbox";
import OCLayerManager from "@/components/map/OCLayerManageer";
import features from "@/utils/features";
import opencity from "@/service/opencity";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { styles } from "@/utils/styles";
import mapboxgl from "mapbox-gl/dist/mapbox-gl";

const titleCache = {

}

export default {
  components: {
    OCLayerManager,
    MglMap,
    MglMarker
  },
  data() {
    return {
      mapAccessToken: process.env.VUE_APP_MAPBOX_KEY,
      mapboxZoom: null,
      hoveredFeatures: [],
      mapStyle: opencity.getDefaultMapStyle(),
      loadingStyle: false,
      tooltipDebounce: {
        timeout: null,
        featureId: null
      },
      bounds: [
        [55.8042578, 57.8678961],
        [56.6555925, 58.1792804]
      ],
      mounted: false
    };
  },
  watch: {
    async zoom(val) {
      await this.$nextTick();
      this.mapboxZoom = val;
      this.$refs.map.$forceUpdate();
    },
    mapStyle(val) {
      if (typeof val === "string") return;
      this.$refs.dataLayerManager?.reorderLayers();
    },
    highlight(val) {
      if (val)
        this.map.fitBounds(features.featureFocusBounds(val), {
          animate: false,
          padding: 100
        });
    },
    draw: {
      handler(val, old) {
        const type = val?.config?.type;
        const oldType = old?.config?.type;
        if (type === "Polygon") {
          this.whenMapAvailable(b => this.initDraw(val));
        } else {
          if (this.mapboxDraw) {
            this.map.removeControl(this.mapboxDraw);
            this.mapboxDraw = null;
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    jumpToUserLocation(options = {}) {
      navigator.geolocation.getCurrentPosition(
        res => {
          const center = new mapboxgl.LngLat(
            res.coords.longitude,
            res.coords.latitude
          );
          const radius = res.coords.accuracy;
          this.whenMapAvailable(() => {
            this.map?.fitBounds(center.toBounds(radius), {
              maxZoom: 17,
              ...options
            });
            this.setZoom();
          });
        },
        null,
        { enableHighAccuracy: true }
      );
    },
    initDraw(info) {
      if (!this.mapboxDraw) {
        this.mapboxDraw = new MapboxDraw({
          styles,
          defaultMode: info.geometry ? "simple_select" : "draw_polygon",
          displayControlsDefault: false,
          controls: { polygon: true, trash: true }
        });
      }
      const map = this.map;
      const mdraw = this.mapboxDraw;

      mdraw.save = () => {
        const features = mdraw.getAll()?.features;
        if (!features?.length) return (info.geometry = null);
        info.geometry = {
          type: "MultiPolygon",
          coordinates: features.flatMap(({ geometry }) => {
            if (geometry.type === "Polygon") {
              return [geometry.coordinates];
            } else if (geometry.type === "MultiPolygon") {
              return geometry.coordinates;
            }
            return [];
          })
        };
        this.$emit("update:draw", info);
      };

      const save = () => this.mapboxDraw.save();

      if (!map._drawInitialized) {
        map._drawInitialized = true;
        map.on("draw.create", save);
        map.on("draw.update", save);
        map.on("draw.delete", save);
        map.addControl(mdraw, "bottom-left");
      }

      mdraw.deleteAll();
      if (info.geometry) {
        mdraw.add(info.geometry);
      }
    },
    getSelectionArea(point) {
      return [
        [point.x - 2, point.y - 2],
        [point.x + 2, point.y + 2]
      ];
    },
    onMapMouseMove(e) {
      if (this.hoveredFeatures)
        this.hoveredFeatures.forEach(el =>
          e.map.setFeatureState(
            el,
            { hover: false }
          )
        );
      let features = [];
      if (this.largeMap) {
        const bbox = this.getSelectionArea(e.mapboxEvent.point);
        features = this.$refs.dataLayerManager?.queryFeatures(bbox);
      } else {
        features = this.$refs.dataLayerManager?.queryFeatures(
          e.mapboxEvent.point
        );
        if (features.length) features = [features[0]];
      }

      const featureId = features[features.length - 1]?.id || null
      this.activateTooltip(featureId)

      if (!features.length) {
        e.map.getCanvas().style.cursor = "";
        return;
      }
      e.map.getCanvas().style.cursor = "pointer";
      features.forEach(feature =>
        e.map.setFeatureState(
          feature,
          { hover: true }
        )
      );
      this.hoveredFeatures = features;
    },
    resetTooltip() {
      const app = this.$root.$children[0];
      app.tooltip = null;
      this.tooltipDebounce.featureId = null

      if (this.tooltipDebounce.timeout) {
        clearTimeout(this.tooltipDebounce.timeout)
        this.tooltipDebounce.timeout = null
      }
    },
    activateTooltip(featureId) {
      if (this.tooltipDebounce.featureId !== featureId) {
        const app = this.$root.$children[0];
        this.resetTooltip()

        this.tooltipDebounce.featureId = featureId;

        if (featureId) {
          if (titleCache[featureId]) {
            app.tooltip = {
              type: 'text',
              text: titleCache[featureId]
            }
          } else {
            this.tooltipDebounce.timeout = setTimeout( () => {
              app.tooltip = {type: 'loading'}
              opencity.getObject(featureId).then((res) => {
                titleCache[featureId] = res.title
                if (this.tooltipDebounce.featureId !== featureId) return
                app.tooltip = {type: 'text', text: res.title}
              })
            }, 500)
          }
        }
      }
    },
    onMapMouseLeave(e) {
      this.resetTooltip();
      e.map.getCanvas().style.cursor = "";
      this.hoveredFeatures.forEach(el =>
        e.map.setFeatureState(
          el,
          { hover: false }
        )
      );
    },
    updatePoint(lat, lng, setCenter = true) {
      this.draw.geometry = {
        type: "Point",
        coordinates: [lng, lat]
      };
      if (setCenter && this.draw?.config.bound) this.map.setCenter([lng, lat]);
      this.$emit("update:draw", this.draw);
    },
    async showPopup(id, map, point) {
      const obj = await opencity.getObject(id);
      const picSrc = obj.media[0]
        ? opencity.getMediaPreviewUrl(obj.media[0])
        : "/no-photo.png";

      const template = `
      <a href="/objects/${id}" target="_blank" class="d-flex">
        <img style="object-fit: cover;" width="80" height="50" class="rounded" src="${picSrc}" >
        <div class=" ml-2">
          <div class="font-weight-bold">
            ${obj.title || obj.data.genus?.name}
          </div>
          <div>
            ${obj.objectType.title}
          </div>
          <div>
          ${obj.onModeration ? "На модерации" : ""}
        </div>
        </div>
      </a>`;

      new mapboxgl.Popup()
        .setLngLat(
          obj.geometry.type === "Point" ? obj.geometry.coordinates : point
        )
        .setHTML(template)
        .addTo(map);
    },
    onDrag() {
      if (this.draw?.config.type === "Point" && this.draw.config.bound) {
        const lngLat = this.map.getCenter();
        this.updatePoint(lngLat.lat, lngLat.lng, false);
      }
    },
    onMapClick(e) {
      const point = e.mapboxEvent.point;
      const bbox = [
        [point.x - 2, point.y - 2],
        [point.x + 2, point.y + 2]
      ];

      let features1 = this.$refs.dataLayerManager?.queryFeatures(point);
      let feature = features1[features1.length - 1];

      if (!this.largeMap && feature) {
        this.showPopup(feature.id, this.map, e.mapboxEvent.lngLat);
        return;
      }

      if (this.draw?.config.type === "Point") {
        const lngLat = e.mapboxEvent.lngLat;
        this.updatePoint(lngLat.lat, lngLat.lng);
      }

      if (this.largeMap) {
        let features = this.$refs.dataLayerManager?.queryFeatures(bbox);
        // const featureLayer = features?.[features.length - 1]?.layer.id;
        // if (featureLayer) {
        //   features = features.filter(el => el.layer.id === featureLayer);
        // }
        // if (
        //   features.length > 1 &&
        //   features.every(el => el.id === features[0].id)
        // )
        //   features = [features[0]];

        let feature_ids = features?.map(el => el.id);
        feature_ids = feature_ids.filter((el, i) => {
          const index = feature_ids.indexOf(el);
          debugger;
          return !(index !== -1 && index < i);
        });

        if (features) {
          this.$emit(
            "update:activeItems",
            feature_ids?.map(id => ({ id }))
          );
          return null;
        }
      }
    },
    onMapLoad2(ev) {
      this.map = ev.map;
      this.$refs.dataLayerManager?.reorderLayers();
      this.$emit("loaded:map", this.map);
    },
    whenMapAvailable(cb) {
      if (!this.map) this.$once("loaded:map", cb);
      else cb(this.map);
    },
    async getMap() {
      if (this.map) return this.map;
      return await new Promise(resolve => this.$once("loaded:map", resolve));
    },
    focusGeometry(geometry, options) {
      const combinedOptions = {
        maxDuration: 8000,
        curve: 1,
        speed: 1.2,
        ...options
      };
      const focus = () => {
        this.map.fitBounds(
          features.featureFocusBounds(geometry),
          combinedOptions
        );
        this.setZoom();
      };
      this.whenMapAvailable(focus);
    },
    focusItem(item, options) {
      if (!item.geometry) return;
      this.focusGeometry(item.geometry, options);
    },
    setZoom(event) {
      // A BUG (?) doesn't let the slot content update on the same tick its wrapper emitted the change
      this.mapboxZoom = this.map?.getZoom();
    },
    forceZoom(val) {
      this.$refs.map?.map?.setZoom(val);
    }
  },
  computed: {
    markerLatLng: {
      get() {
        return (
          this.draw?.config.type === "Point" && this.draw.geometry?.coordinates
        );
      }
    },
    highlight() {
      return (
        (this.options.zone?.type === "object" &&
          this.options.zone.geometry) ||
        (this.options.zone?.type === "region" && this.options.zone.borders)
      );
    },
    requiredZoom() {
      if (!this.mounted) return 0;
      return (
        this.$refs.dataLayerManager.visibleTypes.reduce((prev, el) => {
          return Math.max(el.style?.minZoom ?? 0, prev);
        }, 0) ?? 0);
    },
    objectsVisible() {
      return this.mapboxZoom ? this.mapboxZoom >= this.requiredZoom : this.zoom >= this.requiredZoom;
    }
  },
  name: "OCMap",
  props: {
    coords: {},
    filters: {},
    toggleInfoPanel: {},
    zoom: {},
    options: {},
    activeItems: {},
    draw: {},
    mapClass: {},
    largeMap: {
      default: false,
      type: Boolean
    }
  }
};
</script>

<style lang="scss">
.click-through {
  pointer-events: none !important;
}

.map-controls {
  .map-tooltip {
    position: absolute;
    background: black;
    width: 30px;
    height: 30px;
  }
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  > * {
    pointer-events: auto;
  }
  &__panel-toggle.v-btn {
    //position: absolute;
    background: white;
    padding: 12px;
    left: 0;
    //top: 12px;
    min-width: 0 !important;
  }
  .v-btn {
    border-radius: 4px;
  }
  &__bottom {
    position: absolute;
    pointer-events: none;
    bottom: 0;
    width: 100%;
    > * {
      pointer-events: auto;
    }
    &-offset {
      transform: translate(0, -46px);
    }
  }
  .controls-container {
    pointer-events: none;
    > *:not(.spacer) {
      pointer-events: auto;
    }
  }
  &__zoom {
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
  }
}
</style>
