const osm = {
  version: 8,
  sources: {
    'raster-tiles': {
      type: 'raster',
      tiles: [
        // 'https://mt.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
        'https://tile.openstreetmap.org/{z}/{x}/{y}.png'
      ],
      tileSize: 256,
      attribution:
            '© <a target="_top" rel="noopener" href="https://osm.org/copyright">OpenStreetMap</a> Contributors'
    }
  },
  layers: [
    {
      id: 'simple-tiles',
      type: 'raster',
      source: 'raster-tiles',
      minzoom: 0,
      maxzoom: 22
    }
  ]
}

export default osm
