<template>
  <v-autocomplete v-on="$listeners" item-text="name" item-value="id" :items="lists" @focus="onFocus" v-bind="$attrs">
  </v-autocomplete>
</template>
<script>
import opencity from "@/service/opencity";
const PERIOD = 10000;

export default {
  name: 'OCListAutocomplete',
  data: () => ({
    lists: null,
    lastUpdate: 0
  }),
  methods: {
    onFocus() {
      this.updateLists();
    },
    updateLists(force = false) {
      if (!force && (this.lastUpdate > Date.now() - PERIOD)) return;

      this.lastUpdate = Date.now();
      opencity
        .get("/lists")
        .then(res => res.json())
        .then(lists => (this.lists = lists));
    }
  },
  created() {
    this.updateLists();
  }
}
</script>
