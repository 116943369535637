<template>
  <div v-if="!loading" class="c-container page">
    <span class="text-h5 font-weight-medium">{{ user.fullName }}</span>
    <div class="d-flex my-4 align-start">
      <div style="flex: 1 1 50%">
        <div class="d-flex">
          <v-avatar style="outline: 1px solid grey" class="grey lighten-3 mr-12" size="128">
            <v-img v-if="user.avatar" />
            <v-icon v-else size="92">mdi-account</v-icon>
          </v-avatar>
          <ul>
            <li>Житель города</li>
            <li>Домашний регион: <b>Пермь</b></li>
          </ul>
        </div>
        <p class="my-4">
          В системе: с {{ datetime2(user.registrationDate) }}
        </p>
        <p class="my-4">
          В личном кабинете собрана основная информация о Вас, Вашем текущем статусе,
          контактах и активностях. По умолчанию эти данные открыты для всех. Изменить
          настройки приватности Вы можете во вкладке «<router-link to="/settings">Настройки</router-link>».
        </p>
      </div>
      <div v-if="isMe" class="info--sidebar pl-6 d-md-block d-none">
        <UserNavBlock :userId="userId"/>
        <ShareBlock />
      </div>
    </div>
  </div>
</template>

<script>
import opencity from "@/service/opencity";
import useAuth from "@/mixins/useAuth";
import {datetime2} from "@/utils/datetime";
import ShareBlock from "@/components/ShareBlock.vue";
import UserNavBlock from "@/views/User/UserNavBlock.vue";

export default {
  name: "UserProfile.vue",
  components: {UserNavBlock, ShareBlock},
  data() {
    return {
      loading: true,
      userDataInternal: null,
      datetime2
    }
  },
  metaInfo() {
    return {
      title: this.user?.fullName
    }
  },
  mixins: [
    useAuth
  ],
  computed: {
    mediaSrc() {
      return opencity.getMediaUrl();
    },
    userId() {
      return this.$route.params.id;
    },
    user() {
      return this.userDataInternal
    },
    isMe() {
      return this.OCUserObject?.id == this.userId
    }
  },
  watch: {
    userId: {
      immediate: true,
      async handler(val) {
        this.loading = true;
        const user = await opencity.getPerson(val);
        this.userDataInternal = user;
        this.loading = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.info--sidebar {
  width: 340px;
  flex: 0 1 auto;
.v-sheet {
  box-shadow: 0 0 8px rgb(0 0 0 / 8%);
.v-list-item {
  text-transform: uppercase;
  font-weight: 500;
  opacity: 0.7;
  font-size: 14px;
}
a.v-list-item--active {
  opacity: 1;
}
a:hover {
  text-decoration: none;
}
}
position: sticky;
top: 12px;

}
</style>
