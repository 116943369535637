<template>
  <div v-if="!loading" class="c-container page">
    <span class="text-h5 font-weight-medium">Настройки</span>
    <div class="d-flex my-4 align-start">
      <div style="flex: 1 1 50%">
        <v-sheet class="px-3 rounded">
        <v-form ref="form" lazy-validation @submit.prevent="submit" class="py-4" style="max-width">
          <div>Имя *</div>
          <v-text-field autocomplete="" v-model="first_name" :rules="[required_rule]" outlined dense ></v-text-field>
          <div>Фамилия *</div>
          <v-text-field v-model="last_name" :rules="[required_rule]" outlined dense ></v-text-field>
          <div>Отчество</div>
          <v-text-field v-model="middle_name" outlined dense ></v-text-field>
          <div>Email *</div>
          <v-text-field disabled id="email" v-model="email" :rules="[required_rule, email_rule]" autocomplete="email" hint="На указанный адрес электронной почты будет отправлена ссылка для подтверждения почты" outlined dense ></v-text-field>
          <div class="d-flex">
            <v-spacer/>
            <v-btn :loading="loading" type="submit" depressed color="primary" large class="ml-auto">Сохранить</v-btn>
          </div>
        </v-form>
        </v-sheet>
        <v-list class="rounded-lg mt-4">
          <v-list-item v-if="!changePassword" @click="changePassword = true">Сменить пароль</v-list-item>
          <v-form v-if="changePassword" ref="changePasswordForm" class="pa-3">
            <v-text-field
              :type="showPassword ? 'text' : 'password'"
              :append-icon="
              showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
            "
              @click:append="showPassword = !showPassword"
              :rules="oldPasswordRules"
              v-model="oldPassword" outlined dense label="Текущий пароль"></v-text-field>
            <v-text-field :rules="passwordRules" v-model="newPassword" type="password" outlined dense label="Новый пароль"></v-text-field>
            <v-text-field :rules="[val => val === newPassword || 'Пароли не совпадают']" type="password" outlined dense label="Повторите пароль"></v-text-field>
            <div class="d-flex">
              <v-spacer />
              <v-btn @click="changePassword = false" depressed class="mx-2">Отмена</v-btn>
              <v-btn @click="requestPasswordChange" depressed color="primary">Изменить пароль</v-btn>
            </div>
          </v-form>
        </v-list>
        <v-dialog v-model="dialog">
          <span v-text="dialog_content"></span>
          <v-btn @click="dialog = false">Закрыть</v-btn>
        </v-dialog>
      </div>
      <div class="info--sidebar pl-6 d-md-block d-none">
        <UserNavBlock :userId="OCUserObject.id"/>
      </div>
    </div>
  </div>
</template>

<script>
import opencity from "@/service/opencity";
import useAuth from "@/mixins/useAuth";
import {datetime2} from "@/utils/datetime";
import UserNavBlock from "@/views/User/UserNavBlock.vue";

export default {
  name: "Register",
  components: {UserNavBlock},
  metaInfo: {
    title: 'Регистрация',
  },
  data() {
    return {
      first_name: "",
      middle_name: "",
      last_name: "",
      email: "",
      password: "",
      agreement: true,
      required_rule: val => !!val || "Обязательное поле",
      password_match_rules: [val => val === this.password || "Пароли не совпадают"],
      agreement_rules: [val => val || ""],
      email_rule: val => /.+@.+/.test(val) || "Неверный формат",
      loading: false,
      dialog: false,
      dialog_content: null,
      success: false,
      changePassword: false,
      showPassword: false,
      newPassword: '',
      oldPassword: '',
      oldPasswordRules: [
        v => v?.length || "Обязательное поле"
      ],
      passwordRules: [
        v =>
          (v?.length >= 8 &&
            /\d/.test(v) &&
            /[A-ZА-Я]/.test(v) &&
            /[a-zа-я]/.test(v)) ||
          "Должен содержать не менее 8 символов, как минимум одну заглавную, одну строчную букву и одну цифру"
      ],
      wrongPasswordDialog: false,
    }
  },
  mixins: [
    useAuth
  ],
  watch: {
    OCUserObject: {
      immediate: true,
      handler(u) {
        console.log(u)
        this.first_name = u.firstName
        this.middle_name = u.middleName
        this.last_name = u.lastName
        this.email = u.email
      }
    }
  },
  methods: {
    requestPasswordChange() {
      if (!this.$refs['changePasswordForm']?.validate()) {
        return;
      }

      if (this.newPassword?.length) {
        opencity.changePassword(this.oldPassword, this.newPassword).then(() => {
          this.changePassword = false;
        }).catch(e => {
          this.wrongPasswordDialog = true
        })
      }
    },
    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      try {
        this.loading = true
        const response = await opencity.post("/people/" + this.OCUserObject.id, {
          firstName: this.first_name,
          middleName: this.middle_name || null,
          lastName: this.last_name,
          email: this.email,
          // password: this.password
        })
        if (response.ok) {
          this.success = true;
          await opencity.getMe()
        } else {
          this.dialog_content = await response.json()
          this.dialog = true
        }
      } catch (e) {
        this.dialog = true
        this.dialog_content = e
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.info--sidebar {
  width: 340px;
  flex: 0 1 auto;
  .v-sheet {
    box-shadow: 0 0 8px rgb(0 0 0 / 8%);
    .v-list-item {
      text-transform: uppercase;
      font-weight: 500;
      opacity: 0.7;
      font-size: 14px;
    }
    a.v-list-item--active {
      opacity: 1;
    }
    a:hover {
      text-decoration: none;
    }
  }
  position: sticky;
  top: 12px;

}
</style>
