<script>
import AddIssueDescriptionStepTree from "./AddIssueDescriptionStepTree";
import AddIssueDescriptionStepTGroup from "./AddIssueDescriptionStepTGroup";

export default {
  functional: true,
  render(createElement, context) {
    if (context.props.value.objectType.id === 1) {
      return createElement(AddIssueDescriptionStepTree, context.data, context.children);
    }
    return createElement(AddIssueDescriptionStepTGroup, context.data, context.children);
  }
};
</script>

<style></style>
