<template>
  <div class="c-container page" v-if="loading">
    Подождите...
  </div>
  <div class="c-container page" v-else-if="!success">
    <div class="d-flex">
      <span class="text-h5 font-weight-bold">Смена пароля</span>
      <v-spacer/>
    </div>
    <v-form ref="changePasswordForm" class="py-4" style="max-width: 580px">
      <v-text-field :rules="passwordRules" v-model="newPassword" type="password" outlined dense label="Новый пароль"></v-text-field>
      <v-text-field :rules="[val => val === newPassword || 'Пароли не совпадают']" type="password" outlined dense label="Повторите пароль"></v-text-field>
      <div class="d-flex">
        <v-spacer />
        <v-btn @click="requestPasswordChange" depressed color="primary">Изменить пароль</v-btn>
      </div>
    </v-form>
    <v-dialog max-width="420px" content-class="white" v-model="dialog">
      <div v-text="dialog_content" class="pa-4" style="max-height: 420px; overflow-y: auto"></div>
      <v-btn @click="dialog = false" depressed>Закрыть</v-btn>
    </v-dialog>
  </div>
  <div class="c-container page" style="max-width: 580px" v-else-if="success">
    Пароль успешно изменен!
    <div class="d-flex pt-10 mx-n2">
      <v-btn large class="mx-2" depressed color="primary" to="/login">Войти</v-btn>
    </div>
  </div>
  <div class="c-container page" v-else>
    Произошла ошибка
  </div>
</template>

<script>
import opencity from "@/service/opencity";

export default {
  name: "ConfirmRegistration",
  data() {
    return {
      loading: false,
      passwordRules: [
        v =>
          (v?.length >= 8 &&
            /\d/.test(v) &&
            /[A-ZА-Я]/.test(v) &&
            /[a-zа-я]/.test(v)) ||
          "Должен содержать не менее 8 символов, как минимум одну заглавную, одну строчную букву и одну цифру"
      ],
      newPassword: '',
      dialog: false,
      dialog_content: null,
    }
  },
  methods: {
    async requestPasswordChange() {
      if (!this.$refs['changePasswordForm']?.validate()) {
        return;
      }

      const token = this.$route.query.token

      try {
        this.loading = true;
        if (!this.newPassword?.length) return;

        let res = await opencity.post("/auth/resetConfirm", {
          token,
          newPassword: this.newPassword
        });
        let text = await res.text();

        if (text === "OK") {
          await this.$router.replace("?success=true")
        } else {
          throw new Error(text);
        }
      } catch(e) {
        this.dialog = true
        this.dialog_content = e
      } finally {
        this.loading = false;
      }
    }
  },
  computed: {
    success() {
      return this.$route.query.success
    }
  }
}
</script>

<style scoped>

</style>
