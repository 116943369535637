<template>
  <div>
    <div class="banner-block">
      <div class="c-container ma-auto d-flex align-center pb-4 pb-md-0 flex-column">
        <div class="text-h5 font-weight-bold mt-12 text-center">Заметили проблему доступности? – Сообщите о ней.</div>
        <div class="text-center text-body-2 font-weight-regular my-2" style="max-width: 600px">В разделе “Проблемы и решения” отображаются все сообщения о проблемах,
          связанные с доступностью объектов социальной инфраструктуры. Если Вы
          столкнулись с барьером доступности, сообщите о проблеме и следите за ходом её

          решения.</div>
        <v-btn to="/objects/map" color="orange darken-2" dark large depressed class="d-none d-md-flex">Открыть карту</v-btn>
        <div class="justify-center align-end mt-8 d-none d-md-flex">
          <v-sheet class="stat-sheet">
            <div class="stat-sheet__value orange--text">1 250</div>
            <div class="stat-sheet__title">проблем
              решено</div>
          </v-sheet>
          <v-sheet class="stat-sheet">
            <div class="stat-sheet__value orange--text">278</div>
            <div class="stat-sheet__title">сообщений
              опубликовано за
              все время</div>
          </v-sheet>
          <v-sheet class="stat-sheet">
            <div class="stat-sheet__value orange--text">619</div>
            <div class="stat-sheet__title">в работе прямо
              сейчас</div>
          </v-sheet>
        </div>
      </div>
    </div>
    <div class="pa-5">
      <v-text-field hide-details solo flat @click:append="()=>null" style="max-width: 600px; margin: auto" color="orange"  prepend-inner-icon="mdi-magnify" :placeholder="$vuetify.breakpoint.mobile ? 'Найти сообщения...' : 'Найти сообщение по названию или адресу...'" outlined dense></v-text-field>
      <v-btn class="d-md-none mt-4" block large depressed color="orange darken-2" dark>Открыть карту</v-btn>
    </div>
    <div class="py-3 white">
      <div class="c-container">
        <v-tabs background-color="transparent" color="black" centered>
          <v-tab>РЕШЕННЫЕ ПРОБЛЕМЫ</v-tab>
          <v-tab>НОВЫЕ СООБЩЕНИЯ</v-tab>
        </v-tabs>
      </div>
      <div class="c-container overflow-x-auto overflow-y-visible py-4">
        <div class="d-flex mx-n2">
          <div class="item-card mx-2" v-for="object in cards" :key="object.id">
            <div class="item-card__cover">
              <img :src="object.cover_src || '@/assets/no-image.png'">
            </div>
            <div class="px-3 py-4 d-flex flex-column">
              <router-link to="/">{{ object.title }}</router-link>
              <p>
                {{ object.location }}
              </p>
              <v-spacer/>
              <div class="">{{ object.category.name }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-center py-2">
        <router-link class="text-center" to="/">Все сообщения</router-link>
      </div>
    </div>
    <div class="c-container py-2">
      <h6 class="text-h6 font-weight-bold my-4">Категории проблем</h6>
      <div class="category-grid mb-5">
        <category-card v-for="cat in OCClassifier" :key="cat.title" color="orange" class="pa-2 px-3">
          <span
            class="category-card__color"
            style="width: 34px"
            v-html="cat.icon.value"
            v-if="cat.icon.svg"
          ></span>
          <v-icon
            class="category-card__color"
            size="36"
            v-text="cat.icon"
            v-else
          ></v-icon>
          <div
            class="text-center font-weight-bold"
            v-text="cat.title"
          ></div>
        </category-card>
      </div>
    </div>
  </div>
</template>

<script>
import CategoryCard from '../../../components/CategoryCard.vue'

import { MglGeojsonLayer, MglMap } from 'vue-mapbox'
import osm from '@/map_styles/osm.js'

import categories from '@/data/categories'
import data from "@/data";
import useClassifier from "@/mixins/useClassifier";

export default {
  name: 'Home',
  data () {
    return {
      showAll: false,
      categories,
      currentService: 0,
      mapStyle: osm,
      coords: {"lng":56.469770480103284,"lat":58.01933146095297},
      mapAccessToken: 'pk.eyJ1Ijoib3BsYXllciIsImEiOiJja2JpYTc3aGwwZGFiMzFxdjRwbzJmbXM3In0.UIGvvUiespfBvN6S-7wqhA',
      mapOptions: {
        zoomControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        gestureHandling: 'greedy',
        autocompleteSessionToken: null
      },
      polygonLayerStroke: {
        type: 'line',
        layout: {},
        paint: {
          'line-color': '#414249',
          'line-width': 1.5
        }
      },
      polygonLayerFill: {
        type: 'fill',
        layout: {},
        paint: {
          'fill-color': '#72BF44',
          'fill-opacity': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            0.38,
            0
          ]
        }
      },
      zoom: 9,
      hovState: null
    }
  },
  mixins: [useClassifier],
  components: {
    CategoryCard,
    // MglGeojsonLayer,
    // MglMap
    // HelloWorld
  },
  computed: {
    cards() {
      return data.objects
    }
  },
  methods: {
    polMouseMove (e) {
      if (e.mapboxEvent.features.length > 0) {
        if (this.hovState !== null) {
          e.map.setFeatureState(
            { source: 'polygons', id: this.hovState },
            { hover: false }
          )
        }
        e.map.getCanvas().style.cursor = 'pointer'
        this.hovState = e.mapboxEvent.features[0].id
        e.map.setFeatureState(
          { source: 'polygons', id: this.hovState },
          { hover: true }
        )
      }
    },
    polMouseLeave (e) {
      if (this.hovState !== null) {
        e.map.getCanvas().style.cursor = ''
        e.map.setFeatureState(
          { source: 'polygons', id: this.hovState },
          { hover: false }
        )
      }
      this.hovState = null
    }
  }
}
</script>



<style lang="scss" scoped>
.category-grid {
  display: grid;
  @media (max-width: 960px) {
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  }
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 12px;
}

.banner-block {
  background: #ff980011;
  // height: 320px;
}

.stat-sheet {
  border-radius: 16px 16px 0 0 !important;
  min-width: 120px;
  max-width: 160px;
  min-height: 40px;
  padding: 8px 16px;
  margin: 0 8px;
}

.stat-sheet__value {
  font-weight: 600;
  font-size: 24px;
}

.stat-sheet__title {
  display: inline-block;
  line-height: normal;
}

.item-card {
  .item-card__cover {
    height: 136px;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  border-radius: 6px;
  flex-grow: 1;
  flex-basis: 186px;
  min-width: 186px;
  // box-shadow: 0 3px 1px -2px rgb(0 0 0 / 10%), 0 2px 2px 0 rgb(0 0 0 / 7%), 0 1px 5px 0 rgb(0 0 0 / 6%);
  box-shadow: 0 0 8px rgb(0 0 0 / 8%);
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    &.v-tab {
      text-decoration: none;
    }
    &.v-btn {
      text-decoration: none;
    }
    &.v-list-item {
      text-decoration: none;
    }
  }
}

.static-map {
  height: 400px;
}

.static-map__info-block {
  // padding: 56px 40px 12px 40px;
  background: white;
  align-self: start;
  min-width: 280px;
  border-radius: 12px;
  margin: 12px;
}

.static-map__title {
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
}
</style>
