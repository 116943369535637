<template>
  <div class="d-flex flex-column align-center w-100">
    <span class="text-h5 font-weight-bold mt-6 mt-md-8" id="stage-0"
      >1. Выберите категорию</span
    >
    <div class="mb-4 mb-md-6 mt-2" style="width: 100%">
      <span>Поиск типа объекта</span>
      <search-field-mock @category="setStage(1)" @topic="setStage(2)" />
    </div>
    <v-container fluid>
      <v-row class="d-flex ma-n5 ma-md-n6">
        <v-col
          class="pa-2 pa-md-3 col-6 col-md-4"
          v-for="cat in categories"
          :key="cat.name"
        >
          <div
            v-ripple="!cat.disabled && { class: 'text--white' }"
            class="
                  demo-card
                  d-flex
                  flex-column
                  justify-center
                  align-center
                  pa-2
                "
            :class="{ 'demo-card_disabled': cat.disabled }"
            @click="cat.disabled || setStage(1)"
          >
            <div class="details">
              <v-icon
                size="24"
                @mousedown.stop="null"
                @click.stop="dialog_details = true"
                >mdi-help-circle-outline</v-icon
              >
            </div>
            <span
              class="v-icon category-card__color"
              style="width: 34px"
              v-html="cat.icon.value"
              v-if="cat.icon.svg"
            ></span>
            <v-icon
              class="ma-2"
              :size="$vuetify.breakpoint.mdAndUp ? '32' : '24'"
              v-text="cat.icon"
              v-else
            ></v-icon>
            <span class="mb-2 mb-md-4" v-text="cat.title"></span>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <category-details-dialog v-model="dialog_details" />
  </div>
</template>

<script>
import CategoryDetailsDialog from "./CategoryDetailsDialog.vue";
import SearchFieldMock from "./SearchFieldMock.vue";
import useClassifier from "@/mixins/useClassifier";

export default {
  components: { SearchFieldMock, CategoryDetailsDialog },
  props: {
    stage: {
      type: Number
    }
  },
  mixins: {
    useClassifier
  },
  computed: {
    categories() {
      return this.OCClassifier;
    }
  },
  methods: {
    setStage(st) {
      this.$emit("update:stage", st);
    }
  },
  data() {
    return {
      dialog_details: false
    };
  }
};
</script>

<style lang="scss" scoped>
.demo-card {
  height: 126px;
  background: #ececed7f;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  cursor: pointer;
  user-select: none;
  border-radius: 12px;
  &.demo-card_disabled {
    cursor: not-allowed;
    opacity: 0.5;
    > .v-icon {
      color: currentColor;
      opacity: 0.7;
    }
  }
  span {
    font-weight: 500;
    line-height: 18px;
    font-size: 15px;
    text-align: center;
  }
  @media (min-width: 960px) {
    height: 108px;
    span {
      line-height: 24px;
      font-size: 16px;
    }
  }
  > .v-icon {
    color: #72bf44;
  }
  &:hover:not(.demo-card_disabled) {
    background: #72bf44;
    color: white;
    > .v-icon {
      color: white;
    }
    .details .v-icon {
      color: white;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
  }
  position: relative;
  .details {
    position: absolute;
    top: 8px;
    right: 8px;
    .v-icon {
      opacity: 0.3;
    }
  }
}
</style>
