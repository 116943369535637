<template>
  <v-app>
    <template v-if="tooltip">
      <div class="app-tooltip" :style="tooltipStyle" v-if="tooltip.type === 'loading'" >
        <v-progress-circular size="16" width="2" indeterminate />
      </div>
      <div class="app-tooltip" :style="tooltipStyle" v-else>
        {{tooltip.text}}
      </div>
    </template>

    <template v-if="layout">
      <AppInfoBanner />
      <AppNavbar :current-service="currentService" />

      <v-main>
        <router-view/>
      </v-main>

      <AppFooter v-if="showFooter" />
    </template>
    <v-main v-else>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

import AppFooter from "@/components/AppFooter.vue";
import AppNavbar from "@/AppNavbar.vue";
import AppInfoBanner from "@/AppInfoBanner.vue";

export default {
  name: 'App',
  components: {AppInfoBanner, AppNavbar, AppFooter},
  metaInfo: {
      titleTemplate: (title) => title ? `${title} – Зеленый город` : 'Зеленый город'
  },
  data: () => ({
    tooltip: null,
    tooltipStyle: {
      top: 0,
      left: 0,
      right: 'auto',
      bottom: 'auto'
    },
  }),
  created() {
    document.addEventListener('mousemove', this.updateTooltipLocation);
  },
  methods: {
    updateTooltipLocation(event) {
      this.tooltipStyle.top = `${event.clientY + 16}px`;
      this.tooltipStyle.left = `${event.clientX + 16}px`;
      this.tooltipStyle.right = 'auto';
      this.tooltipStyle.bottom = 'auto';

      if (event.clientX > window.innerWidth - 200) {
        this.tooltipStyle.left = 'auto';
        this.tooltipStyle.right = `${window.innerWidth - event.clientX + 16}px`;
      }
      if (event.clientY > window.innerHeight - 60) {
        this.tooltipStyle.top = 'auto';
        this.tooltipStyle.bottom = `${window.innerHeight - event.clientY + 16}px`;
      }
    }
  },
  computed: {
    layout() {
      if (this.$route.fullPath.includes("/objects/add") || this.$route.fullPath.includes("/media/add")) return false;
      return true;
    },
    currentService() {
      return this.$route.fullPath?.includes("/objects") ? 'objects' : null;
    },
    showFooter() {
      return this.$route.name !== "ObjectsMap";
    }
  },
}
</script>

<style lang="scss">
@media (hover: none) {
  .app-tooltip {
    display: none;
  }
}

.app-tooltip {
  position: fixed;
  //min-width: 32px;
  //min-height: 16px;
  padding: 4px;
  font-size: 0.8em;
  font-weight: 500;
  color: white;
  background: #00000088;
  border-radius: 4px;
  z-index: 1000;
}

.v-application.text-h5 {
  font-size: 2rem !important;
}

.page-title {
  font-size: 2rem;
  line-height: 1;
  margin: 12px 0;
  @media (max-width: 960px) {
    font-size: 1.5rem;
  }
}

.page {
  margin: 72px auto;
  @media (max-width: 960px) {
    margin: 40px auto;
  }
}

html {
  overflow-y: auto;
}

.v-slide-group__prev, .v-slide-group__next {
  display: none !important;
}

.v-tab {
  letter-spacing: normal !important;
}

.appbar-menu {
  background: rgba(0,0,0,0.3);
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.theme--light.v-application {
  background: #f8f6ef  !important;
  // background: rgb(236, 239, 241) !important;
}

.logo-title {
  font-weight: bold;
  font-size: 1.25rem;
}
.ls-normal {
  letter-spacing: normal !important;
}
.app-bar__button {
  //font-weight: 600 !important;
  letter-spacing: normal !important;
  padding: 0 8px !important;
  margin: 0 4px;
  font-size: 14px !important;
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
  &.v-btn--active {
    opacity: 1;
  }
}

.appbar__service-block a {
  display: block;
  color: #313D43 !important;
  margin: 16px 0;
}

body {
  font-family: Roboto, sans-serif;
}

.custom-field-border-behavior {
  fieldset {
    transition-property: color !important; // exclude border-width from transition properties
    border-width: 0px !important; // remove default 1px border for outlined fields
  }
  &:hover fieldset {
    border-width: thin !important;
  }
  &:hover {
    border-width: thin !important;
  }
  &.v-input--is-focused fieldset {
    border-width: 2px !important;
  }
}

//body .v-application {
//  line-height: normal;
//}
.hidden {
  visibility: hidden;
}

</style>
