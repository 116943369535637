<template>
  <div class="search-field-mock" style="width: 100%">
    <v-menu
      content-class="search-field-mock__results"
      v-model="showResults"
      offset-y
    >
      <template v-slot:activator>
        <v-text-field
          v-model="query"
          dense
          hide-details
          class="mt-2"
          height="48"
          clearable
          outlined
          prepend-inner-icon="mdi-magnify"
          @input="showResults = query.length > 2"
          @focus="showResults = query.length > 2"
          :placeholder="'Найти...'"
        >
        </v-text-field>
      </template>
      <div>
        <div
          @click="selectCategory"
          class="category font-weight-medium text-h6"
        >
          Зеленые насаждения
        </div>
        <div @click="selectTopic" class="topic">Деревья</div>
        <div @click="selectTopic" class="topic">Группы деревьев</div>
      </div>
    </v-menu>
  </div>
</template>

<script>
export default {
  data: () => ({
    showResults: false,
    query: ""
  }),
  methods: {
    selectCategory() {
      this.$emit("category");
    },
    selectTopic() {
      this.$emit("topic");
    }
  }
};
</script>

<style lang="scss">
.search-field-mock {
  .v-input__prepend-inner,
  .v-input__append-inner {
    margin-top: 12px !important;
  }
}
.search-field-mock__results {
  box-shadow: none !important;
  border: thin solid black;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top: none;
  background: white;
  .topic {
    padding: 8px 16px;
    cursor: pointer;
    &:hover {
      background: #f6f6f6;
      color: #0682be;
    }
  }
  .category {
    padding: 0px 16px;
    margin-top: 8px;
    cursor: pointer;
    &:hover {
      // background: #f6f6f6;
      color: #0682be;
    }
  }
}
</style>
