<template>
  <v-btn
    :x-small="!sizeNormal"
    :height="sizeNormal ? null : 40"
    :width="wide ? undefined : 40"
    class="ma-1 rounded-lg"
    :color="!$vuetify.theme.dark && 'white'"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </v-btn>
</template>

<script>
export default {
  props: {
    wide: Boolean,
    sizeNormal: Boolean
  },
  name: "OCMapButton"
}
</script>

<style scoped>

</style>
