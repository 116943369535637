<template>
  <v-carousel
    v-model="currentSlide"
    hide-delimiters
    :show-arrows="media.length > 1 || (media.length > 0 && allowAdd)"
    show-arrows-on-hover
    class="my-4 rounded"
    :touch="{
      left: () => $refs.carousel.next(),
      right: () => $refs.carousel.prev()
    }"
    :height="height"
    v-if="media && media.length && carousel"
  >
    <v-carousel-item
      v-for="(cur, i) in media"
      :src="imagePreviewUrl(i)"
      :key="cur.id || cur.src"
      @click="openGallery(i)"
      style="cursor: pointer;"
      class="grey lighten-2"
    >
    </v-carousel-item>
    <v-carousel-item @click="$emit('add')" key="last" v-if="allowAdd">
      <AddPhotoArea />
    </v-carousel-item>
<!--    <v-sheet v-if="media[currentSlide].group" class="rounded pa-1 d-flex align-center elevation-0" style="pointer-events: none; position: absolute; left: 8px; bottom: 8px; background-color: #00000088; max-width: 60%; line-height: normal">-->
<!--      <span v-text="media[currentSlide].group"></span>-->
<!--    </v-sheet>-->
    <v-sheet
      dark
      class="rounded pa-1 d-flex align-center elevation-0"
      style="position: absolute; right: 8px; bottom: 8px; background-color: #00000088"
    >
      <v-icon class="mr-1" small>mdi-camera-outline</v-icon>
      <span
        class="text-body-2"
        v-text="
          (currentSlide === media.length
            ? '+'
            : currentSlide + 1) + ' / ' + media.length
        "
      />
    </v-sheet>
  </v-carousel>
  <v-img class="my-4 rounded grey lighten-2" :contain="contain" style="cursor: pointer;" :width="tile ? height : undefined" :height="height" @click="openGallery(currentSlide)" :src="imagePreviewUrl(currentSlide)" v-else-if="media && media.length">
    <v-sheet
      v-if="counter"
      dark
      class="rounded pa-1 d-flex align-center elevation-0"
      style="position: absolute; right: 8px; bottom: 8px; background-color: #00000088"
    >
      <v-icon class="mr-1" small>mdi-camera-outline</v-icon>
      <span
        class="text-body-2"
        v-text="media.length"
      />
    </v-sheet>
  </v-img>
  <AddPhotoArea @click="$emit('add')" :style="{ height: height + 'px' }" v-else-if="allowAdd" />
  <NoPhoto :height="height" class="my-4" v-else />
</template>
<script>
import opencity from "@/service/opencity";
import PhotoSwipe from "photoswipe";
import NoPhoto from "@/components/NoPhoto.vue";
import "photoswipe/dist/photoswipe.css";
import AddPhotoArea from "@/components/object/AddPhotoArea.vue";
import {datetime2} from "@/utils/datetime";

export default {
  name: "OCMediaPreview",
  components: {AddPhotoArea, NoPhoto },
  props: {
    contain: {
      default: false,
      type: Boolean
    },
    counter: {
      default: true,
      type: Boolean
    },
    media: {
      default: []
    },
    height: {
      default: 200
    },
    carousel: {
      default: false,
      type: Boolean
    },
    disableGallery: {
      default: false,
      type: Boolean
    },
    fixedSlide: {},
    tile: {
      type: Boolean,
      default: false
    },
    allowAdd: {
      default: false,
      type: Boolean
    },
    addTo: {
      default: null
    }
  },
  data: () => ({
    currentSlideInner: 0
  }),
  computed: {
    currentSlide: {
      get() {
        if (this.fixedSlide !== undefined) {
          return this.fixedSlide;
        }
        return this.currentSlideInner;
      },
      set(val) {
        this.currentSlideInner = val;
      },
    },
  },
  created() {
    this.$on('add', () => {
      if (this.addTo) {
        this.$router.push(`/objects/${this.addTo}/media/add`);
      }
    });
  },
  methods: {
    imageFullUrl(index) {
      return opencity.getMediaUrl(this.media[index]);
    },
    imagePreviewUrl(index) {
      return opencity.getMediaPreviewUrl(this.media[index]);
    },
    openGallery(index = 0) {
      if (this.disableGallery) return;
      if (this.photoSwipe?.isOpen) return;
      const dataSource = this.media.map((el, i) => ({
        src: this.imageFullUrl(i),
        width: el.width,
        height: el.height,
        alt: el.group,
        author: el.author,
        date: el.uploadDate
      }));
      this.photoSwipe = new PhotoSwipe({
        dataSource,
        wheelToZoom: true,
        index
      });
      this.photoSwipe.on("uiRegister", () => {
        if (this.allowAdd)
          this.photoSwipe.ui.registerElement({
            name: "add-photo",
            ariaLabel: "Add photo",
            order: 9,
            isButton: true,
            html: "Добавить фото",
            onClick: () => {
              this.$emit("add");
              this.photoSwipe.close();
            }
          });
        this.photoSwipe.ui.registerElement({
          name: "author",
          order: 9,
          html: "empty",
          isButton: false,
          appendTo: "wrapper",
          onInit: (el, pswp) => {
            let el2 = document.createElement("div");
            el.prepend(el2);
            this.photoSwipe.on('change', () => {
              const slide = this.photoSwipe.currSlide;
              const author = slide.data.author;
              const date = slide.data.date;
              const text = author?.fullName;

              if (text) {
                el2.innerText = text;
                el2.classList.remove("d-none");
              } else {
                el2.classList.add("d-none");
              }

              el.childNodes[1].nodeValue = date ? datetime2(date) : "";
              // slide.container.parentElement.
              // el.innerHTML = captionHTML || '';
            });
          }
        });
      })
      this.photoSwipe.init();
      this.photoSwipe.on("close", () => (this.photoSwipe = null));
      this.$once("$route", () => this.photoSwipe.close());
    }
  }
};
</script>

<style>
.pswp__button.pswp__button--add-photo {
  width: fit-content;
  height: fit-content;
  padding: 12px;
  color: white;
  font-weight: 500;
  border: 1px solid white;
  border-radius: 4px;
  margin: 8px 0;
}

.pswp__author > div {
  background: rgba(255, 255, 255, 0.1);
  font-size: 16px;
  max-width: 400px;
  padding: 8px 12px;
  border-radius: 8px;
  margin-right: 4px;
}
.pswp__author {
  color: #fff;
  position: absolute;
  left: 0;
  bottom: 0;
  margin: 16px;
  display: flex;
  align-items: center;
}

.hidden-caption-content {
  display: none;
}
</style>
