<template>
  <div>
    <div class="c-container page" v-if="!success">
      <div class="d-flex">
        <span class="text-h5 font-weight-bold">Регистрация</span>
        <v-spacer/>
      </div>
      <v-form ref="form" lazy-validation @submit.prevent="submit" class="py-4" style="max-width: 580px">
        <div>Имя *</div>
        <v-text-field autocomplete="" v-model="first_name" :rules="[required_rule]" outlined dense filled></v-text-field>
        <div>Фамилия *</div>
        <v-text-field v-model="last_name" :rules="[required_rule]" outlined dense filled></v-text-field>
        <div>Отчество</div>
        <v-text-field v-model="middle_name" outlined dense filled></v-text-field>
        <div>Email *</div>
        <v-text-field id="email" v-model="email" :rules="[required_rule, email_rule]" autocomplete="email" persistent-hint hint="На указанный адрес электронной почты будет отправлена ссылка для подтверждения регистрации" outlined dense filled></v-text-field>
        <div class="d-flex flex-wrap mx-n2">
          <div class="flex-grow-1 mx-2">
            <div>Пароль *</div>
            <v-text-field v-model="password" :rules="passwordRules" type="password" autocomplete="new-password" outlined dense filled></v-text-field>
          </div>
          <div class="flex-grow-1 mx-2">
            <div>Повторите пароль *</div>
            <v-text-field :rules="password_match_rules" type="password" autocomplete="new-password" outlined dense filled></v-text-field>
          </div>
        </div>
<!--        <div>Контактный телефон</div>-->
<!--        <v-text-field type="phone" outlined dense filled></v-text-field>-->
        <v-checkbox v-model="agreement" :rules="agreement_rules">
          <template v-slot:label>
            <span>
            Я согласен(на) с условиями <router-link to="/about/privacy">Пользовательского соглашения</router-link> и <router-link to="/about/privacy#1">Регламента обработки информации</router-link>
              </span>
          </template>
        </v-checkbox>
        <div class="d-flex">
          <v-spacer/>
          <v-btn :loading="loading" type="submit" depressed color="primary" large class="ml-auto">Зарегистрироваться</v-btn>
        </div>
      </v-form>
      <v-dialog v-model="dialog">
        <span v-text="dialog_content"></span>
        <v-btn @click="dialog = false">Закрыть</v-btn>
      </v-dialog>
    </div>
    <div class="c-container page" v-else>
      <p>Спасибо за регистрацию на портале!</p>
      <p>На указанный вами Email направлено письмо с дальнейшими инструкциями.</p>

      <p>(если вы не видите его в своем почтовом ящике, проверьте папку спама или отправьте письмо снова)</p>
    </div>
  </div>
</template>

<script>
import opencity from "@/service/opencity";

export default {
  name: "Register",
  metaInfo: {
    title: 'Регистрация'
  },
  data() {
    return {
      first_name: "",
      middle_name: "",
      last_name: "",
      email: "",
      password: "",
      agreement: true,
      required_rule: val => !!val || "Обязательное поле",
      password_match_rules: [val => val === this.password || "Пароли не совпадают"],
      agreement_rules: [val => val || ""],
      email_rule: val => /.+@.+/.test(val) || "Неверный формат",
      passwordRules: [
        v =>
          (v?.length >= 8 &&
            /\d/.test(v) &&
            /[A-ZА-Я]/.test(v) &&
            /[a-zа-я]/.test(v)) ||
          "Должен содержать не менее 8 символов, как минимум одну заглавную, одну строчную букву и одну цифру"
      ],
      loading: false,
      dialog: false,
      dialog_content: null,
      success: false
    }
  },
  methods: {
    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      try {
        this.loading = true
        const response = await opencity.post("/register", {
          firstName: this.first_name,
          middleName: this.middle_name || null,
          lastName: this.last_name,
          email: this.email,
          password: this.password
        })
        if (response.ok) {
          this.success = true;
        } else {
          this.dialog_content = await response.json()
          this.dialog = true
        }
      } catch (e) {
        this.dialog = true
        this.dialog_content = e
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
</style>
