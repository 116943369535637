import Vue from 'vue'
import GmapVue from 'gmap-vue'

Vue.use(GmapVue, {
    load: {
        key: 'AIzaSyB5i1DsnYtZyMRTL0pzSZ8_v9S5CrlC6bk'
    },
    installComponents: true,
    dynamicLoad: false
})
