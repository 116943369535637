<template>
  <div v-if="!loading" class="c-container page">
    <span class="text-h5 font-weight-medium">Мои действия</span>
    <div class="d-flex my-4 align-start">
      <div style="flex: 1 1 50%">
        <span class="text-h6">Добавленные объекты</span>
        <div v-if="objects.length === 0 && isLastPage" class="my-3">Вы ещё не создавали объектов</div>
        <div>
          <template  v-for="item in objects">
            <v-menu absolute offset-y v-if="item.onModeration && !item.deactivatedAt" :key="item.id">
              <v-list>
                <v-list-item :to="'/objects/add?editId=' + item.id">Редактировать</v-list-item>
                <v-list-item @click="deleteObject(item)">Удалить</v-list-item>
              </v-list>
              <template #activator="{ on }">
                <OCObjectListItem v-on="on" class="object-list__item" :key="item.id" @click="1" :item="item" :value="item.id" />
              </template>
            </v-menu>
            <OCObjectListItem v-else class="object-list__item" :key="item.id" @click="1" :item="item" :value="item.id" />
          </template>
          <v-btn @click="getNextPageObjects" v-if="!isLastPage" :loading="loadingObjects" block text>Показать ещё</v-btn>
        </div>
<!--        <span class="text-h6">Фото</span>-->
<!--        <div class="d-flex">-->
<!--        </div>-->
      </div>
      <div v-if="isMe" class="info--sidebar pl-6 d-md-block d-none">
        <UserNavBlock :userId="userId"/>
        <ShareBlock />
      </div>
    </div>
  </div>
</template>

<script>
import opencity from "@/service/opencity";
import useAuth from "@/mixins/useAuth";
import {datetime2} from "@/utils/datetime";
import ShareBlock from "@/components/ShareBlock.vue";
import UserNavBlock from "./UserNavBlock.vue";
import OCObjectListItem from "./OCObjectListItem.vue";

export default {
  name: "UserData",
  components: {OCObjectListItem, UserNavBlock, ShareBlock},
  data() {
    return {
      loading: true,
      userDataInternal: null,
      datetime2,
      lastPage: null,
      objects: [],
      loadingObjects: false
    }
  },
  metaInfo() {
    return {
      title: this.user?.fullName
    }
  },
  mixins: [
    useAuth
  ],
  methods: {
    async getNextPageObjects() {
      if (this.loadingObjects) return;
      this.loadingObjects = true;
      let pageId = (this.lastPage?.number ?? -1) + 1;
      this.lastPage = await opencity.listObjects(5, pageId, 'updatedAt,desc', null, [], null, null, null, [], this.userId, null, null, "null");
      this.objects.push(...this.lastPage.content);
      this.loadingObjects = false;
    },
    async deleteObject(object) {
      await opencity.delete('/objects/' + object.id);
      object.deactivatedAt = true;
    }
  },
  computed: {
    mediaSrc() {
      return opencity.getMediaUrl();
    },
    userId() {
      return this.$route.params.id;
    },
    user() {
      return this.userDataInternal
    },
    isMe() {
      return this.OCUserObject?.id == this.userId
    },
    isLastPage() {
      return this.lastPage?.last;
    }
  },
  watch: {
    userId: {
      immediate: true,
      async handler(val) {
        if (val != this.OCUserObject?.id) return;
        this.loading = true;
        const user = await opencity.getPerson(val);
        this.userDataInternal = user;
        this.loading = false;
        this.getNextPageObjects();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.object-list__item {
  background: white;
  border-radius: 8px;
  margin: 8px 0;
  border: rgba(0,0,0,0.1) solid thin;
}

.info--sidebar {
  width: 340px;
  flex: 0 1 auto;
.v-sheet {
  box-shadow: 0 0 8px rgb(0 0 0 / 8%);
.v-list-item {
  text-transform: uppercase;
  font-weight: 500;
  opacity: 0.7;
  font-size: 14px;
}
a.v-list-item--active {
  opacity: 1;
}
a:hover {
  text-decoration: none;
}
}
position: sticky;
top: 12px;

}
</style>
