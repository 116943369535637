<template>
  <div class="row my-2">
    <div v-if="options.advanced" class="col-12 py-0 d-flex filter-tabs w-100">
      <v-btn tile text @click="$emit('update:type', true)" :class="!typeFilters && 'active-filter-tab'" :key="null">
        <v-badge dot :value="isAnyActive()" >
          Общие
        </v-badge>
      </v-btn>
      <v-btn tile text @click="$emit('update:type', 1)" :class="typeFilters === 1 && 'active-filter-tab'" v-if="!options.types || options.types.includes(1)" :key="1">
        <v-badge dot :value="isAnyActive(1)">
          Деревья
        </v-badge>
      </v-btn>
      <v-btn tile text @click="$emit('update:type', 14)" :class="typeFilters === 14 && 'active-filter-tab'" v-if="!options.types || options.types.includes(14)">
        <v-badge dot :value="isAnyActive(14)">
          Кустарники
        </v-badge>
      </v-btn>
    </div>
    <template v-if="!type || (type === true || type === 'true')">
      <div v-if="zone" class="col-md-4 col-12">
        <v-label for="filter-0">Территория</v-label>
        <ZoneSelector
          hide-details
          v-if="definitions.zone"
          id="filter-zone"
          v-model="options.zone"
          @change="$blur"
          :background-color="background"
          filled
          outlined
          dense
          class="custom-field-border-behavior"
        >
        </ZoneSelector>
      </div>
      <div class="col-md-4 col-12" v-if="authenticated">
        <v-label>Входит в список</v-label>
        <div class="d-flex">
          <OCListAutocomplete
            clearable
            hide-details
            placeholder="(Пусто)"
            v-model="optionsCopy.list"
            dense
            outlined
            filled
            :background-color="background"
            class="custom-field-border-behavior"
          />
        </div>
      </div>
      <div class="col-md-4 col-12">
        <v-label for="filter-1">Обслуживающая организация</v-label>
        <v-select
          v-model="optionsCopy['tag~responsible_team_text']"
          placeholder="Любая"
          outlined
          filled
          dense
          :background-color="background"
          hide-details
          clearable
          class="custom-field-border-behavior"
          :items="[
            'МКУ «ГорЗеленСтрой»',
          ]"
        ></v-select>
      </div>
      <div class="col-md-4 col-12">
        <v-label>Дата создания</v-label>
        <div class="d-flex">
          <OCDatePicker
            input-class="rounded-r-0 custom-field-border-behavior"
            v-model="optionsCopy.created.from"
            placeholder="От"
            dense
            clearable
            hide-details
            filled
            outlined
            :background-color="background"
            input-style="width: 50%"
          />
          <v-divider style="z-index:1; margin: 0" vertical />
          <OCDatePicker
            input-class="rounded-l-0 custom-field-border-behavior"
            input-style="width: 50%;"
            placeholder="До"
            v-model="optionsCopy.created.to"
            dense
            clearable
            hide-details
            filled
            outlined
            :background-color="background"
          />
        </div>
      </div>
      <div class="col-md-4 col-12">
        <v-label>Последнее обновление</v-label>
        <div class="d-flex">
          <OCDatePicker
            input-class="rounded-r-0 custom-field-border-behavior"
            v-model="optionsCopy.updated.from"
            placeholder="От"
            dense
            clearable
            hide-details
            filled
            outlined
            :background-color="background"
            input-style="width: 50%"
          />
          <v-divider style="z-index:1; margin: 0" vertical />
          <OCDatePicker
            input-class="rounded-l-0 custom-field-border-behavior"
            placeholder="До"
            v-model="optionsCopy.updated.to"
            dense
            clearable
            hide-details
            filled
            outlined
            :background-color="background"
            input-style="width: 50%;"
          />
        </div>
      </div>
      <v-divider v-if="typeFilters" style="flex: 0 0 100%;
    max-width: 100%;"/>
    </template>
    <template v-if="typeFilters === 1">
      <div class="col-md-4 col-12">
        <v-label for="filter-1">Род</v-label>
        <v-autocomplete
          type="search"
          class="custom-field-border-behavior"
          clearable
          hide-details
          id="filter-1"
          v-model="optionsCopy['tag~1,genus.name']"
          :items="genera"
          item-value="filter"
          item-text="name"
          :background-color="background"
          placeholder="Все"
          filled
          outlined
          dense
        ></v-autocomplete>
      </div>
      <div class="col-md-4 col-12">
        <v-label for="filter-2">Вид</v-label>
        <v-autocomplete
          type="search"
          autocomplete="off"
          class="custom-field-border-behavior"
          autocorrect="off"
          autocapitalize="off"
          name="q"
          role="combobox"
          clearable
          hide-details
          id="filter-2"
          v-model="optionsCopy['tag~1,species']"
          :items="species"
          item-value="filter"
          item-text="name"
          :background-color="background"
          placeholder="Все"
          filled
          outlined
          dense
        ></v-autocomplete>
      </div>
      <div class="col-md-4 col-12">
        <v-label for="filter-3">Состояние</v-label>
        <v-select
          clearable
          class="custom-field-border-behavior"
          v-model="optionsCopy['tag~1,condition']"
          hide-details
          item-value="filter"
          item-text="name"
          id="filter-3"
          :items="conditions"
          :background-color="background"
          placeholder="Все"
          filled
          outlined
          dense
        ></v-select>
      </div>
      <div class="col-md-4 col-12">
        <v-label>Высота (м)</v-label>
        <div class="d-flex">
          <VTextField
            class="rounded-r-0 custom-field-border-behavior"
            v-model.number="optionsCopy['tag~1,height'].from"
            placeholder="От"
            type="number"
            dense
            clearable
            hide-details
            filled
            outlined
            :background-color="background"
            style="width: 50%"
          />
          <v-divider style="z-index:1; margin: 0" vertical />
          <VTextField
            class="rounded-l-0 custom-field-border-behavior"
            style="width: 50%;"
            placeholder="До"
            type="number"
            v-model.number="optionsCopy['tag~1,height'].to"
            dense
            clearable
            hide-details
            filled
            outlined
            :background-color="background"
          />
        </div>
      </div>
      <div class="col-md-4 col-12">
        <v-label>Диаметр ствола (см)</v-label>
        <div class="d-flex">
          <VTextField
            class="rounded-r-0 custom-field-border-behavior"
            v-model.number="optionsCopy['tag~1,trunk_diam'].from"
            placeholder="От"
            type="number"
            dense
            clearable
            hide-details
            filled
            outlined
            :background-color="background"
            style="width: 50%"
          />
          <v-divider style="z-index:1; margin: 0" vertical />
          <VTextField
            class="rounded-l-0 custom-field-border-behavior"
            style="width: 50%;"
            placeholder="До"
            type="number"
            v-model.number="optionsCopy['tag~1,trunk_diam'].to"
            dense
            clearable
            hide-details
            filled
            outlined
            :background-color="background"
          />
        </div>
      </div>
      <div class="col-md-4 col-12">
        <v-label>Диаметр кроны (м)</v-label>
        <div class="d-flex">
          <VTextField
            class="rounded-r-0 custom-field-border-behavior"
            v-model.number="optionsCopy['tag~1,crown_diam'].from"
            placeholder="От"
            type="number"
            dense
            clearable
            hide-details
            filled
            outlined
            :background-color="background"
            style="width: 50%"
          />
          <v-divider style="z-index:1; margin: 0" vertical />
          <VTextField
            class="rounded-l-0 custom-field-border-behavior"
            style="width: 50%;"
            placeholder="До"
            type="number"
            v-model.number="optionsCopy['tag~1,crown_diam'].to"
            dense
            clearable
            hide-details
            filled
            outlined
            :background-color="background"
          />
        </div>
      </div>
      <div class="col-md-4 col-12">
        <v-label>Повреждения</v-label>
        <div class="d-flex">
          <VAutocomplete
            class="custom-field-border-behavior"
            v-model="optionsCopy['tag~1,damage'].has"
            placeholder="Любые"
            :items="damage"
            item-text="name"
            item-value="id"
            dense
            clearable
            hide-details
            filled
            outlined
            :background-color="background"
          />
        </div>
      </div>
    </template>
    <template v-if="typeFilters === 14">
      <div class="col-md-4 col-12">
        <v-label for="filter-1">Род</v-label>
        <v-autocomplete
          type="search"
          class="custom-field-border-behavior"
          clearable
          hide-details
          id="filter-1"
          v-model="optionsCopy['tag~14,genus.name']"
          :items="generaBush"
          item-value="filter"
          item-text="name"
          :background-color="background"
          placeholder="Все"
          filled
          outlined
          dense
        ></v-autocomplete>
      </div>
      <div class="col-md-4 col-12">
        <v-label for="filter-2">Вид</v-label>
        <v-autocomplete
          type="search"
          autocomplete="off"
          class="custom-field-border-behavior"
          autocorrect="off"
          autocapitalize="off"
          name="q"
          role="combobox"
          clearable
          hide-details
          id="filter-2"
          v-model="optionsCopy['tag~14,species']"
          :items="speciesBush"
          item-value="filter"
          item-text="name"
          :background-color="background"
          placeholder="Все"
          filled
          outlined
          dense
        ></v-autocomplete>
      </div>
      <div class="col-md-4 col-12">
        <v-label for="filter-3">
          Состояние
        </v-label>
        <v-select
          clearable
          class="custom-field-border-behavior"
          v-model="optionsCopy['tag~14,condition']"
          hide-details
          item-value="filter"
          item-text="name"
          id="filter-3"
          :items="conditions"
          :background-color="background"
          placeholder="Все"
          filled
          outlined
          dense
        ></v-select>
      </div>
      <div class="col-md-4 col-12">
        <v-label>Повреждения</v-label>
        <div class="d-flex">
          <VAutocomplete
            class="custom-field-border-behavior"
            v-model="optionsCopy['tag~14,damage'].has"
            placeholder="Любые"
            :items="damage"
            item-text="name"
            item-value="id"
            dense
            clearable
            hide-details
            filled
            outlined
            :background-color="background"
          />
        </div>
      </div>
    </template>
    <div style="position: sticky; bottom: 0; z-index: 1" class="col-12 d-flex align-center flex-wrap">
      <v-alert
        icon="mdi-information-outline"
        v-if="!typeFilters && !options.advanced "
        class="text-center text--secondary"
      >
        Больше фильтров доступно после выбора типа объектов
      </v-alert>
      <v-alert
        icon="mdi-information-outline"
        v-if="noFilters"
        class="text-center text--secondary"
      >
        Фильтры отсутствуют
      </v-alert>
      <v-spacer />
      <v-btn depressed class="mx-2" @click="$emit('update:options', null)">Сбросить</v-btn>
      <v-btn depressed color="primary" @click="updateFilters">Применить</v-btn>
    </div>
  </div>
</template>
<script>
import ZoneSelector from "@/components/ZoneSelector";
import genera, { species } from "@/assets/genera";
import OCDatePicker from "@/components/OCDatePicker";
import OCListAutocomplete from "@/components/OCListAutocomplete";
import damage from "@/views/ServiceObjects/NewObject/damage";
import generaBush, { species as speciesBush } from "@/assets/generaBush";
import {defaultFilterDefinitions} from "@/assets/filters";
import opencity from "@/service/opencity";

const conditions = ["Хорошее", "Удовлетворительное", "Неудовлетворительное"];

export default {
  name: "OCFilterBlock",
  components: {OCListAutocomplete, OCDatePicker, ZoneSelector },
  props: {
    background: {},
    options: {},
    type: {},
    selectedRegion: {},
    zone: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      optionsCopy: {}
    };
  },
  created() {
    this.$watch(() => this.filters?.['1,species'], (val) => {
      console.log("Species selected: " + val);
      if (!val) return;

      const searchList = this.typeFilters === 1 ? species : speciesBush;

      this.filters["1,genus.name"] = searchList.find(
        el => el.name === val
      )?.parent?.name;
    });
  },
  watch: {
    options: {
      handler(val) {
        this.optionsCopy = val;
      },
      immediate: true
    },
    speciesBush(val) {
      if (this.filters['14,species'] === "empty" || this.filters['14,species'] === "any") return;
      if (!val?.find(el => el.name === this.filters['14,species'])) {
        this.filters['14,species'] = null;
      }
    },
    species(val) {
      if (this.filters['1,species'] === "empty" || this.filters['1,species'] === "any") return;
      if (!val?.find(el => el.name === this.filters['1,species'])) {
        this.filters['1,species'] = null;
      }
    }
  },
  computed: {
    authenticated() {
      return opencity.authenticated;
    },
    definitions() {
      return defaultFilterDefinitions;
    },
    noFilters() {
      if (this.type && this.type !== true && this.type !== "true") return ![1, 14].includes(this.typeFilters);
      return false;
    },
    damage() {
      // console.log(damage)
      return damage.reduce((o, el) => {
        // console.log(el)
        el.items.forEach(l => o.push({ name: l, group: el.group, id: l.substring(0, 3) }));
        return o;
      }, []);
    },
    filters() {
      return this.optionsCopy.dataFilters;
    },
    typeFilters() {
      if (this.type && this.type !== true && this.type !== "true") return Number(this.type);
      let number = Number(this.optionsCopy.type?.id || this.optionsCopy.type);
      if (!number || isNaN(number)) return null;
      return number;
    },
    conditions() {
      const array = conditions.map(el => ({ name: el }));
      return [
        { name: "(Не Заполнено)", filter: "empty" },
        { name: "(Заполнено)", filter: "any" },
        ...array
      ];
    },
    generaBush: function () {
      return generaBush.sort((a, b) => a.name.localeCompare?.(b.name));
    },
    speciesBush() {
      let speciesPre = (this.speciesPrefilteredBush ?? []).map(el => ({
        name: el.name,
        filter: el.name
      }));
      return [
        { name: "(Не Заполнено)", filter: "empty" },
        { name: "(Заполнено)", filter: "any" },
        ...speciesPre
      ];
    },
    genera: function() {
      return genera.sort((a, b) => a.name.localeCompare?.(b.name));
    },
    species() {
      let speciesPre = (this.speciesPrefiltered ?? []).map(el => ({
        name: el.name,
        filter: el.name
      }));
      return [
        { name: "(Не Заполнено)", filter: "empty" },
        { name: "(Заполнено)", filter: "any" },
        ...speciesPre
      ];
    },
    speciesPrefilteredBush() {
      const genus = this.filters["14,genus.name"];
      if (!genus) return speciesBush;
      return speciesBush.filter(el => el.parent.name === genus);
    },
    speciesPrefiltered() {
      const genus = this.filters["1,genus.name"];
      if (!genus) return species;
      return species.filter(el => el.parent.name === genus);
    }
  },
  methods: {
    updateFilters() {
      this.$emit("update:options", this.optionsCopy);
    },
    isAnyActive(type) {
      const entries = Object.entries(defaultFilterDefinitions);
      let filtered = [];
      if (!type) {
        filtered = entries.filter(([key, def]) => {
          if (key.includes(",")) return false;
          return true;
        });
      } else {
        filtered = entries.filter(([key, def]) => {
          if (!key.includes(",")) return false;
          const [typeId] = key.replace("tag~", "").split(",");
          return Number(typeId) === type;
        });
      }
      return filtered.some(([key, def]) => {
        const value = this.optionsCopy[key];
        return !def.isEmpty(value);
      });
    }
  }
};
</script>

<style lang="scss">
.z-index-1 {
  z-index: 1;
}

.v-label {
  font-size: 0.825rem;
  .data-value {
    line-height: normal;
    white-space: pre-line;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 1rem;
    .theme--dark & {
      color: #fff;
    }
  }
}

.filter-tabs .v-btn {
  border-radius: 0;
  border-bottom: 3px solid transparent;
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.825rem;
  font-weight: 500;
  padding: 0.5rem 1rem;
  transition: all 0.2s ease-in-out;
  .theme--dark & {
    color: rgba(255, 255, 255, 0.7);
  }
  &.active-filter-tab {
    border-bottom: 3px solid currentColor;
    color: var(--v-primary-base);
  }
}
</style>
