<template>
  <v-sheet
    v-ripple="{ class: 'category-card__ripple' }"
    class="
        category-card
        d-flex
        flex-column
        justify-center
        align-center
    "
    v-on="$listeners"
    :style="{ '--category-color': color }"
  >
    <slot />
  </v-sheet>
</template>

<script>
export default {
  props: {
    color: {
      default: "#ff4d63",
      type: String
    }
  }
};
</script>

<style lang="scss">
.category-card {
  min-height: 112px;
  //   background: #ececed7F;
  background: white;
  box-shadow: 0 0 8px rgb(0 0 0 / 8%) !important;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  cursor: pointer;
  user-select: none;
  border-radius: 8px;
  //   span {
  //     font-weight: 500;
  //     line-height: 18px;
  //     font-size: 15px;
  //     text-align: center;
  //   }
  .category-card__ripple {
    color: white;
  }
  .category-card__color {
    color: #ff4d63 !important;
    color: var(--category-color) !important;
  }
  &:hover {
    background: #ff4d63;
    background: var(--category-color);
    color: white;
    .category-card__color {
      color: white !important;
    }
    .details .v-icon {
      color: white;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
  }
  //   position: relative;
  //   .details {
  //     position: absolute;
  //     top: 8px;
  //     right: 8px;
  //     .v-icon {
  //       opacity: 0.3;
  //     }
  //   }
}
</style>
