<template>
  <div>
    <div class="pa-3">
      <slot :search.sync="search" :updateSearch="val => (search = val)">
        <v-text-field prepend-inner-icon="mdi-magnify" placeholder="Найти..." hide-details clearable outlined dense v-model="search">
          Найти...
        </v-text-field>
      </slot>
    </div>
    <v-treeview activatable @update:active="updateActive" :active="active" :search="search" v-model="tree1" selected-color="success" dense open-on-click item-text="title" selectable :items="items1">
      <template #append="{item}">
        <v-icon size="22" @click="$emit('filters', item.id)" class="options-icon" v-if="!item.children">mdi-tune-variant</v-icon>
        <span class="type-count" v-if="statsInternal && statsInternal[item.id]">{{statsInternal[item.id]}}</span>
      </template>
    </v-treeview>
    <v-divider class="my-3" />
    <div class="px-3 my-2 font-weight-bold">Территории</div>
    <v-treeview activatable @update:active="updateActive" :active="active" :search="search" v-model="tree2" selected-color="success" dense open-on-click item-text="title" selectable :items="items2">
      <template #append="{item}">
        <v-icon size="22"  @click="$emit('filters', item.id)" class="options-icon" v-if="!item.children">mdi-tune-variant</v-icon>
        <span class="type-count" v-if="statsInternal && statsInternal[item.id]">{{statsInternal[item.id]}}</span>
      </template>
    </v-treeview>
  </div>
</template>

<script>

import useClassifier from "@/mixins/useClassifier";
import opencity from "@/service/opencity";

export default {
  name: "OCLayerEditor",
  props: {
    value: {},
    options: {
      default: () => ({})
    }
  },
  data: () => ({
    statsInternal: null,
    search: "",
    tree1: [],
    tree2: [],
    active: []
  }),
  mixins: [
    useClassifier
  ],
  watch: {
    value: {
      immediate: true,
      handler(val)
      {
        if (!val) val = [];
        if (val.every(el => this.tree1.includes(el) || this.tree2.includes(el))) return;
        this.tree1 = val.filter(id => this.items1.some(el => el.children.some(el => el.id === id)));
        this.tree2 = val.filter(id => this.items2.some(el => el.children.some(el => el.id === id)));
      }
    },
    resulting(val) {
      this.tree = val;
    }
  },
  methods: {
    updateActive(val) {
      if (!val[0]) return;
      this.$emit("filters", val[0]);
      this.active = [];
    }
  },
  computed: {
    resulting() {
      return [...new Set([...this.tree1, ...this.tree2])];
    },
    tree: {
      set(val) {
        this.$emit('input', val);
      },
      get() {
        return this.value;
      }
    },
    items1() {
      return this.items.filter(el => el.id === 100000 || el.id === 500000);
    },
    items2() {
      return this.items.filter(el => this.items1.includes(el) === false);
    },
    items() {
      return this.OCClassifier.map(el => ({icon: el.icon, id: el.id * 100000, title: el.title, children: el.objectTypes}));
    }
  },
  created() {
    const ch = this.options?.moderated;
    const url = ch === false || ch === "false" ? "/objects/stats_mod" : "/objects/stats";
    opencity
      .get(url)
      .then(res => res.json())
      .then(data => {
        this.statsInternal = data.reduce((o, el) => {
          o[el[0]] = el[1];
          return o;
        }, {});
      });
  }
}
</script>

<style scoped>
/deep/ .v-treeview-node__label {
  white-space: normal !important;
  margin: 8px 0;
}

/deep/ .v-treeview-node__checkbox {
  align-self: start;
  margin-top: 8px;
}

/deep/ .v-treeview-node__content {
  min-height: 24px;
}

/deep/ .v-treeview-node__root {
  /*align-items: start;*/
}

/deep/ .v-treeview-node__root .options-icon {
  display: none;
}

/deep/ .v-treeview-node__root:hover .options-icon {
  display: inline;
}

/deep/ .v-treeview-node__root:hover .type-count {
  display: none;
}
</style>
