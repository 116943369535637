<template>
  <div class="c-container page">
    <span class="text-h5 font-weight-medium">Аналитика</span>
    <div class="d-flex my-4 align-start" style="max-width: 100%">
      <div style="flex: 1 1 0%; overflow: hidden">
        <OCMediaPreview :counter="false" contain :height="slidePreviewHeight" :media="picture1" />
        <OCMediaPreview :counter="false" contain :height="slidePreviewHeight" :media="picture1" :fixed-slide="1" />
        <OCMediaPreview :counter="false" contain :height="slidePreviewHeight" :media="picture1" :fixed-slide="2"  />
<!--        <v-text-field outlined solo prepend-inner-icon="mdi-magnify" flat color="blue" dense></v-text-field>-->
<!--        <v-img src="./Picture_1.jpg" />-->
      </div>
      <div class="info--sidebar pl-6 d-md-block d-none">
<!--        <v-btn block color="blue" dark depressed class="mb-3 py-5">Подписаться на новости</v-btn>-->
<!--        <v-sheet rounded class="overflow-hidden">-->
<!--          <v-list dense>-->
<!--            <v-list-item to="/news" exact>Все новости</v-list-item>-->
<!--&lt;!&ndash;            <v-list-item exact>ГАЗЕТА “ЗДРАВСТВУЙ!”</v-list-item>&ndash;&gt;-->
<!--            <v-list-item exact>Избранное</v-list-item>-->
<!--          </v-list>-->
<!--        </v-sheet>-->
        <ShareBlock />
      </div>
    </div>
  </div>
</template>

<script>
import { fetchNews } from "@/data";
import ShareBlock from "@/components/ShareBlock.vue";
import OCMediaPreview from "@/components/object/OCMediaPreview.vue";

import Picture1 from './Picture_1.jpg';
import Picture2 from './Picture_2.jpg';
import Picture3 from './Picture_3.jpg';

export default {
  name: "Info",
  components: {OCMediaPreview, ShareBlock},

  metaInfo: {
    title: 'Новости'
  },

  data() {
    return {
      picture1: [{
        src: Picture1,
        id: 1,
        width: 1876,
        height: 911
      }, {
        src: Picture2,
        id: 2,
        width: 1875,
        height: 1074
      }, {
        src: Picture3,
        id: 3,
        width: 1894,
        height: 1071
      }]
    }
  },

  created() {
    fetchNews().then(res => this.news = res);
  },

  computed: {
    slidePreviewHeight() {
      return this.$vuetify.breakpoint.mobile ? 240 : 320;
    }
  }
}
</script>

<style lang="scss" scoped>
.news-card {
  box-shadow: 0 0 8px rgb(0 0 0 / 8%);
}

.info--sidebar {
  width: 340px;
  flex: 0 1 auto;
  .v-sheet {
    box-shadow: 0 0 8px rgb(0 0 0 / 8%);
    .v-list-item {
      text-transform: uppercase;
      font-weight: 500;
      opacity: 0.7;
      font-size: 14px;
    }
    a.v-list-item--active {
      opacity: 1;
    }
    a:hover {
      text-decoration: none;
    }
  }
  position: sticky;
  top: 12px;
}
</style>
