<template>
  <v-list-item
    :value="value"
    v-on="$listeners"
    v-bind="$attrs"
    style="min-height:96px;"
    :to="to"
  >
    <template v-if="loading">
      <div>#{{ item.id }}</div>
      <v-spacer />
      <v-progress-circular indeterminate width="2" />
    </template>
    <template v-else>
      <v-list-item-avatar
        tile
        width="100"
        size="80"
        color="grey"
        class="rounded dynamic-saturation"
        v-if="item.media && item.media.length"
      >
        <v-img :src="getPicUrl(item.media[0])"></v-img>
      </v-list-item-avatar>
      <v-list-item-content class="d-flex flex-row">
          <v-list-item-title v-text="item.title" />
          <v-list-item-subtitle v-text="item.objectType.title" />
          <v-list-item-subtitle v-text="item.location" />
          <v-btn depressed v-if="!$vuetify.breakpoint.mdAndUp && status" small>{{ status }}</v-btn>
      </v-list-item-content>
      <v-list-item-action v-if="$vuetify.breakpoint.mdAndUp">
        <v-btn v-if="status" depressed>{{ status }}</v-btn>
        <span class="text-body-2" v-else>{{dateUpdated}}</span>
      </v-list-item-action>
    </template>
  </v-list-item>
</template>
<script>
import opencity from "@/service/opencity";
import {datetime2} from "@/utils/datetime";

export default {
  name: "OCObjectListItem",
  setup() {
    return {
      getPicUrl: opencity.getMediaPreviewUrl
    };
  },
  props: {
    item: {},
    navigateTo: {},
    value: {}
  },
  computed: {
    status() {
      const item = this.item;
      if (item.deactivatedAt) {
        return "Удален"
      }
      if (item.onModeration) {
        return "На модерации"
      }
      return ""
    },
    dateUpdated() {
      return datetime2(this.item.updatedAt);
    },
    to() {
      if (this.item.onModeration) return null;
      return `/objects/${this.item.id}`;
    },
    loading() {
      return !this.item.createdAt;
    }
  },
  watch: {
    loading: {
      immediate: true,
      handler(val) {
        const ac = new AbortController();
        if (val)
          opencity.getObject(this.item.id, { signal: ac.signal }).then(res => {
            this.item = res;
          });
        this.$once("hook:beforeDestroy", () => ac.abort());
      }
    }
  }
};
</script>
<style lang="scss" scoped>
//.v-list-item:focus {
//  outline: 2px solid var(--v-primary-base);
//}
</style>
