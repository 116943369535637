const categories = [
    {
        name: 'yard',
        icon: 'mdi-alert-rhombus-outline',
        title: 'Здравоохранение',
        short_desc: 'Замечания по состоянию\n' +
          'дорог и дорожной\n' +
          'инфраструктуры',
        topics: [{
            title: 'Больницы',
            count: 64662,
            disabled: false
        },{
            title: 'Поликлиники',
            disabled: true
        },{
            title: 'Диспансеры',
            disabled: true
        },{
            title: 'Центры скорой медпомощи и переливания крови',
            disabled: true
        },{
            title: 'Санатории-профилактории',
            disabled: true
        },{
          title: 'Органы управления здравоохранением',
          disabled: true
        },
          {title: ' Специализированные центры ', disabled: true},
          {title: ' Учреждения охраны материнства и детства ', disabled: true},
          {title: ' Центры восстановления и реабилитации ', disabled: true},
          {title: ' Иные учреждения здравоохранения ', disabled: true},],
        disabled: false
    },
    {
        name: 'transport',
        icon: 'mdi-alert-rhombus-outline',
        title: 'Образование',
        topics: [
          {title: ' Школы ', disabled: true},
          {title: ' Детские сады ', disabled: true},
          {title: ' ВУЗы ', disabled: true},
          {title: ' Колледжи и техникумы ', disabled: true},
          {title: ' Организации доп. образования ', disabled: true},
          {title: ' Специализированные центры ', disabled: true},
          {title: ' Инклюзивные центры и адаптированные программы ', disabled: true},
          {title: ' Иные учреждения образования ', disabled: true},
        ],
        short_desc: 'Благоустройство\n' +
          'придомовых территорий',
        disabled: true
    },
    {
        name: 'transport',
        icon: 'mdi-alert-rhombus-outline',
        title: 'Культура',
        topics: [
          {title: ' Библиотеки ', disabled: true},
          {title: ' Дома культуры и клубы ', disabled: true},
          {title: ' Музеи и выставочные залы ', disabled: true},
          {title: ' Театры ', disabled: true},
          {title: ' Кинотеатры ', disabled: true},
          {title: ' Центры культуры ', disabled: true},
          {title: ' Концертные залы ', disabled: true},
          {title: ' Парки и скверы ', disabled: true},
          {title: ' Органы управления культуры ', disabled: true},
          {title: ' Иные учреждения культуры ', disabled: true},
        ],
        short_desc: 'Территории Перми с различными режимами охраны природы',
        disabled: true
    },
    {
        name: 'transport',
        icon: 'mdi-alert-rhombus-outline',
        topics: [
          {title: ' Детско-юношеские спортивные школы ', disabled: true},
          {title: ' Культурно-спортивные комплексы ', disabled: true},
          {title: ' Центры здоровья и детского спорта ', disabled: true},
          {title: ' Физкультурно-оздоровительные центры ', disabled: true},
          {title: ' Школы олимпийского резерва (СШОР) ', disabled: true},
          {title: ' Стадионы ', disabled: true},
        ],
        title: 'Физкультура и спорт',
        short_desc: 'Более 40% территории Перми занимают городские леса',
        disabled: true
    },
    {
        name: 'transport',
        icon: 'mdi-alert-rhombus-outline',
        title: 'Дороги и транспорт',
        topics: [
          {title: ' Автовокзалы ', disabled: true},
          {title: ' Автостоянки ', disabled: true},
          {title: ' Ж/д вокзалы ', disabled: true},
          {title: ' Аэропорты ', disabled: true},
          {title: ' Иные учреждения ', disabled: true},
        ],
        short_desc: 'Экотропы, информационные стенды и площадки для активного отдыха',
        disabled: true
    },
    {
        name: 'transport',
        icon: 'mdi-alert-rhombus-outline',
        title: 'Социальная защита',
        topics: [
          {title: ' Социальные приюты ', disabled: true},
          {title: ' Психоневрологические интернаты ', disabled: true},
          {title: ' Реабилитационные центры ', disabled: true},
          {title: ' Дома ветеранов ', disabled: true},
          {title: ' Дома-интернаты для престарелых и инвалидов ', disabled: true},
          {title: ' Органы управления социальной защиты ', disabled: true},
          {title: ' Центры социальной адаптации ', disabled: true},
          {title: ' Центры социальной помощи семье и детям ', disabled: true},
          {title: ' Геронтологические и геронтопсихиатрические центры ', disabled: true},
          {title: ' Центры помощи детям, оставшимся без попечения родителей ', disabled: true},
          {title: ' Центры психолого-педагогической помощи ', disabled: true},
          {title: ' Иные учреждения соцзащиты ', disabled: true},
        ],
        short_desc: 'Речные долины и другие участки с ограниченным использованием',
        disabled: true
    },
  {
    name: 'transport',
    icon: 'mdi-alert-rhombus-outline',
    title: 'Связь и информация',
    topics: [
      {title: ' Почтовые отделения ', disabled: true},
      {title: ' МФЦ ', disabled: true},
      {title: ' Центры обслуживания клиентов ', disabled: true},
      {title: ' Салоны сотовой связи ', disabled: true},
      {title: ' Иные учреждения связи ', disabled: true},
    ],
    short_desc: 'Речные долины и другие участки с ограниченным использованием',
    disabled: true
  },
  {
    name: 'transport',
    icon: 'mdi-alert-rhombus-outline',
    title: 'Прочие объекты',
    topics: [
      {title: ' Органы местного самоуправления ', disabled: true},
      {title: ' Бюро медико-социальной экспертизы ', disabled: true},
      {title: ' Торговля ', disabled: true},
      {title: ' Банки, финансовые учреждения ', disabled: true},
      {title: ' Общественное питание ', disabled: true},
      {title: ' Бытовое обслуживание ', disabled: true},
      {title: ' Отдых и оздоровления детей ', disabled: true},
      {title: ' Органы власти ', disabled: true},
      {title: ' НКО ', disabled: true},
    ],
    short_desc: 'Речные долины и другие участки с ограниченным использованием',
    disabled: true
  }
]

export default categories
